import React from "react";
import { Card,Button } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import LocationQuantitySelection from "../components/locationQuantitySelection";
import PaymentSelection from "../components/paymentSelection";
import EmailForm from "../components/EmailForm";
import Square from "../components/Square";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { payments } from '@square/web-sdk';
import '../components/styles.css';

const API_URL = process.env.REACT_APP_API;
const Selection = () => {

  // Matching code to reset is in adminOrders
  // Duplicate code in home
  document.getElementById("adminPortalNavLink").removeAttribute("hidden");
  document.getElementById("invNavLink").setAttribute("hidden", "hidden");
  document.getElementById("ordersNavLink").setAttribute("hidden", "hidden");
  document.getElementById("detailsNavLink").setAttribute("hidden", "hidden");
  document.getElementById("adminsNavLink").setAttribute("hidden", "hidden");

  const navigate = useNavigate();
  const [myPayMethod, setPayMethod] = useState("");

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneRegex =
    /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;

  const buttonClicked = (event) => {
    event.preventDefault();
    //console.log(myPayMethod);

    var emailValue = localStorage.getItem("email");
    var phoneValue = localStorage.getItem("phone");

    var successfulEmail = false;
    var successfulPhone = false;
    var redoForm = false;

    //Email
    if (emailValue && emailValue.length > 0) {
      if (emailRegex.test(emailValue)) {
        successfulEmail = true;
      } else {
        redoForm = true;
        alert("Invalid email.");
      }
    }

    //Phone
    if (phoneValue && phoneValue.length > 0) {
      if (phoneRegex.test(phoneValue)) {
        successfulPhone = true;
      } else {
        redoForm = true;
        alert("Invalid phone number.");
      }
    }

    //Check if can go to next page (by clicking payment type)
    if (redoForm === false) {
      if (successfulEmail === true) {
        if (localStorage.getItem("paymentType")){
          setPayMethod(localStorage.getItem("paymentType"));
          if (localStorage.getItem("location")){
            return navigate("../address");
          }else{
            alert("No location was selected.");
          }
        }else{
          alert("No payment method was selected.");
        }
      }
      // if only phone is entered
      if (successfulEmail === false && successfulPhone === true){
        alert("Email is required.");
      }
      if (successfulEmail === false && successfulPhone === false){
        alert("Neither email nor phone was entered.");
      }
    }

  };

  return (
    <div
      className="flex-container"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "25px",
        flexWrap: "wrap",
      }}
    >
      <div style={{ padding: "25px" }}>
        <Card
          style={{
            display: "flex",
            maxWidth: "450px",
            alignItems: "center",
            justifyContent: "center",
          }}
          align="center"
        >
          <Card.Img variant="top" src="Firewoodbundles.jpg" />
          <Card.Body>
            <div id="woodDesc">
              <p id="woodDescTopText" style={{ fontSize: "16.25px" }}><b></b></p>
              <p id="woodDescBottomText" style={{ fontSize: "15px" }}></p>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card style = {{maxWidth:'95vw'}}align="center">
          <Card.Header>
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>Order Form</p>
          </Card.Header>
          <Card.Body>

            <p style={{fontSize:'18px'}}>
            <ListGroup className="list-group-flush">
              <ListGroup.Item style={{padding:'10px'}}><EmailForm /></ListGroup.Item>
              <ListGroup.Item style={{padding:'10px'}}><p style = {{marginBottom:"12px"}}>Select Pickup Location</p><LocationQuantitySelection /></ListGroup.Item>
              <ListGroup.Item style={{paddingTop:'25px', marginTop:"12px"}}>
                <p style={{marginBottom:"25px", marginTop:"-12px"}}>Total Amount: <span id = "total" style={{fontWeight:'normal',fontWeight:'bold'}}></span></p>
                <p style={{fontSize:'18px'}}>Select Payment Option</p><p style={{fontSize:'15px'}}>(Currently Not Accepting Card Payments)</p><PaymentSelection/>
                <br></br>
                <Button onClick={(event) => {
                  buttonClicked(event);
                    }}>
                    Continue
                </Button>
              </ListGroup.Item>
            </ListGroup>

            </p>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Selection;
