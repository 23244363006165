import React from 'react'
import {useEffect, useState} from "react";
import {Container, Row, Col,Image} from 'react-bootstrap'
import './styles.css'

const API_URL = process.env.REACT_APP_API;

const Footer = () => {

    useEffect(() => {
    
        async function getContactInfo(){
          const response = await fetch(`${API_URL}/getHomePageInfo`, {
              method: "GET",
              headers: {
                  "Content-Type": "application/json",
                  //'Authorization': localStorage.getItem('token')
              }
          });
    
          if (!response || !response.ok){
              return console.log('Bad response / no response')
          }
          //console.log(response);
          const data = await response.json();
          //console.log(data);
    
          data.forEach(element => {
            if (element.type === "rotaryContact"){
              document.getElementById("rotaryContactLabel").innerHTML = element.value.replace(/\n\r?/g, '<br />'); // preserve new lines
            }
            if (element.type === "campOacContact"){
              document.getElementById("campOacContactLabel").innerHTML = element.value.replace(/\n\r?/g, '<br />'); // preserve new lines
            }
          });
        }
    
        getContactInfo();
    
    }, []); 

    return(
        <div className="footer">
            <Container fluid>
            <div className = "footer-top">
                <Row>
                    <Col style={{disply:'flex', justifyContent:'left',paddingTop:'50px'}}> 
                            <h4>Rotary Club of Kelowna Ogopogo</h4>
                            <p id="rotaryContactLabel">
                            </p>
                            <p><a className='links' href = "https://portal.clubrunner.ca/824" >website</a></p>
                            <a href="https://portal.clubrunner.ca/824"><img className = "logos" src="officialclublogo-removebg.png" alt="Ogopogo Offical"></img></a>
                    </Col>
                    <Col style={{disply:'flex', justifyContent:'left',paddingTop:'50px'}}>  
                            <h4>Camp OAC</h4>
                            <p id="campOacContactLabel">
                            </p>
                            <p><a className='links'href = "https://www.campoac.com/" >website</a></p>
                            <figure><a href="https://www.campoac.com/"><img className = "logos" src="Camp-Oac.png" alt="Camp OAC"></img></a></figure>
                    </Col>
                    
                </Row>
            </div>

            <div className='copyright'>
            <Row>
                <Col>&copy;2022 Rotary Club of Kelowna Ogopogo. All Rights Reserved.</Col>
            </Row>
            </div>

            </Container>
        </div>
    )
}

export default Footer