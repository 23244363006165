import {useState, useEffect} from "react";

const API_URL = process.env.REACT_APP_API;

const AddressSelection = () => {

    useEffect(() => {

        async function getAddresses(){
            const response = await fetch(`${API_URL}/getInventory`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (!response || !response.ok){
                return console.log('Bad response / no response')
            }
            //console.log(response);
            const data = await response.json();
            //console.log(data);

            var addresses = []
            data.forEach(element => {
                if (element.location === localStorage.getItem("location") && element.quantity >= localStorage.getItem("quantity") && element.status === "active"){
                    addresses.push(element.address);
                }
            });
            
            const addressDropdown = document.getElementById("addressSelect");
            fillDropdown(addressDropdown, addresses);

            function fillDropdown(dropdown, dataProperty){
                if (dataProperty){
                    dataProperty.forEach(element => {
                        if (element !== ""){
                            const dropOption = document.createElement('option');
                            dropOption.value = element;
                            dropOption.innerHTML = element;
                            dropdown.appendChild(dropOption);
                        }
                    });
                    localStorage.setItem("address", dropdown.value);
                }
            }

            addressDropdown.addEventListener("change", async function(event){
                localStorage.setItem("address", event.target.value);
                updateMap(event.target.value);
            });
            updateMap(addressDropdown.value);

            function updateMap(address){
                var addressString = address;
                if (address.charAt(0) === '#' && address.includes('-')){
                    addressString = address.split('-')[1];
                }

                var embedMapURL = "https://www.google.com/maps/embed/v1/place?key=AIzaSyAVi5-z0l9JCnm2-9CNxuQhgQ-t5tKQkKM&q=";
                embedMapURL = embedMapURL + addressString + "&zoom=13&maptype=satellite";
                document.getElementById("iframe").setAttribute("src", embedMapURL);
            }
        }
        getAddresses();

        if (localStorage.getItem("orderNumber")){
            localStorage.removeItem("orderNumber")
        }

    }, []);


    return (
        <div>
        
            <form>
                <select id="addressSelect">
                </select>
            </form>

            <br></br>
            <div id="map">
                <iframe
                id="iframe"
                width="365"
                height="430"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAVi5-z0l9JCnm2-9CNxuQhgQ-t5tKQkKM
                    &q=Kelowna
                    &zoom=13
                    &maptype=satellite"
                >
                </iframe>
            </div>

        </div>
    );

}

export default AddressSelection;