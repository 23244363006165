import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Container,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {useEffect,useState } from "react";

const Navigationbar = () => {
  const navigate = useNavigate();

  useEffect(() => {
    function scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    };

    document.getElementById("contactNavLink").addEventListener("click", function (event) {
      event.preventDefault();
      scrollToBottom();
    });

    //console.log('NavTest');
    if (localStorage.getItem("token")){
      // Mirrored code in admin.js
      const logoutButton = document.getElementById("logoutButton");

      logoutButton.addEventListener("click", function(event){
        event.preventDefault();
        if (localStorage.getItem("token")){
          localStorage.removeItem("token");
        }
        logoutButton.setAttribute("hidden", "hidden");
        navigate("../")
      })
    }
  }, []);

  return (
    <Navbar variant="dark" expand="lg" style = {{backgroundColor:"#17458f"}}>
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            className="rotarylogo"
            src="Rotary-new.png"
            width="150"
            height="54"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto" style={{ fontSize: "20px",color:"white"}} navbarScroll>
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/selection">Order</Nav.Link>
            <Nav.Link id="contactNavLink">Contact</Nav.Link>
          </Nav>
          <Nav className="ms-auto me-3" style={{ fontSize: "18px"}}>
            <Nav.Link id="adminPortalNavLink" as={Link} to="/admin">Admin Portal</Nav.Link>
            <a id="invNavLink" class="scrollLink" href="#inventoryCard">Inventory&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a id="ordersNavLink" class="scrollLink" href="#orderSummaryCard">Orders&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a id="detailsNavLink" class="scrollLink" href="#bagPriceCard">Price / Details&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a id="adminsNavLink" class="scrollLink" href="#adminSummaryCard">Admins&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <Button id="logoutButton" variant="primary" hidden>Logout</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
  );
};

export default Navigationbar;
