import {useState, useEffect} from "react";

const API_URL = process.env.REACT_APP_API;

const LocationQuantitySelection = () => {

    var num = 1;
    localStorage.setItem("quantity", num);
    var totalPrice = 0;
    totalPrice.toFixed(2);
    var bagPrice = 0;
    var discountPrice = 0;
    var discountQuantity = 1;

    useEffect(() => {

      async function getLocations(){
        const response = await fetch(`${API_URL}/getInventory`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                //'Authorization': localStorage.getItem('token')
            }
        });

        if (!response || !response.ok){
            return console.log('Bad response / no response')
        }
        //console.log(response);
        const data = await response.json();
        //console.log(data);

        var locations = {};
        data.forEach(element => {
          if (element.status === "active" && element.quantity > 0){
            if (locations[element.location] && locations[element.location] < element.quantity){
              locations[element.location] = element.quantity;
            } else if (!locations[element.location]) {
              locations[element.location] = element.quantity;
            }
          }
        });
        
        const locationDropdown = document.getElementById("locationSelect");
        fillDropdown(locationDropdown, locations);

        function fillDropdown(dropdown, dataProperty){
          if (dataProperty){
              Object.keys(locations).forEach(function(key) {
                //console.log(key + " " + locations[key]);
                  if (key !== ""){
                      const dropOption = document.createElement('option');
                      dropOption.value = key;
                      dropOption.innerHTML = key;
                      dropdown.appendChild(dropOption);
                  }
              });
              localStorage.setItem("location", locationDropdown.value);
              if (document.getElementById("numBagSelector")){
                refillQuantityDropdown(document.getElementById("numBagSelector"), locations[locationDropdown.value]);
              } else {
                alert("Quantity dropdown not found")
              }
          }
        }

        locationDropdown.addEventListener("change", function(event){
          localStorage.setItem("location", event.target.value);
          if (document.getElementById("numBagSelector")){
            refillQuantityDropdown(document.getElementById("numBagSelector"), locations[locationDropdown.value]);
          } else {
            alert("Quantity dropdown not found")
          }
        });

      }


      async function getPrice() {

        const response = await fetch(`${API_URL}/getPrice`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                //'Authorization': localStorage.getItem('token')
                //"Access-Control-Allow-Origin": "http://localhost:3000",
                //"Access-Control-Allow-Credentials": "true",
            }
            //credentials: 'include'
        }).catch((error) => {
          return console.log(error)
        })
  
        if (!response || !response.ok){
            return console.log('Bad response / no response')
        }
        const data = await response.json();
        //console.log(data);
        bagPrice = data[0].price;
        document.getElementById("total").innerHTML = "$" + data[0].price.toFixed(2);
        localStorage.setItem("total", bagPrice.toFixed(2));
      }

      async function getDiscount() {
        const response = await fetch(`${API_URL}/getDiscount`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
            //credentials: 'include'
          }).catch((error) => {
            return console.log(error)
          })

        if (!response || !response.ok){
            return console.log('Bad response / no response')
        }
        const data = await response.json();
        //console.log(data);

        data.forEach(element => {
            if (element.type === "discountPrice"){
                discountPrice = element.price;
            }
            if (element.type === "discountQuantity"){
                discountQuantity = element.price;
            }
        });

        document.getElementById('woodDescTopText').innerHTML = '<b>$' + discountPrice.toFixed(2) + ' OFF FOR EVERY ' + discountQuantity + ' BAGS PURCHASED</b>';
      }
  
      async function getWoodDesc(){
        const response = await fetch(`${API_URL}/getHomePageInfo`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                //'Authorization': localStorage.getItem('token')
            }
        });
  
        if (!response || !response.ok){
            return console.log('Bad response / no response')
        }
        //console.log(response);
        const data = await response.json();
        //console.log(data);
  
        data.forEach(element => {
          if (element.type === "woodDescription"){
            document.getElementById("woodDescBottomText").innerHTML = element.value;
          }
        });
      }
  
      document.getElementById("numBagSelector").addEventListener('change', (event) => {
        handleChange(event.target.value);
      });
      
      document.getElementById("total").innerHTML = "$" + bagPrice.toFixed(2);
      localStorage.setItem("total", bagPrice.toFixed(2));

      getPrice();
      getDiscount();
      getWoodDesc();

      function handleChange(num) {
        var totalPrice = (num * bagPrice) - (Math.floor(num / discountQuantity) * discountPrice);
        localStorage.setItem("quantity", num);
        
        document.getElementById("total").innerHTML = "$" + totalPrice.toFixed(2);
        localStorage.setItem("total",totalPrice.toFixed(2));
      };


      function refillQuantityDropdown(selectElement, numOptions) {
        var i, L = selectElement.options.length - 1;
        for(i = L; i >= 0; i--) {
           selectElement.remove(i);
        }
        var numb = numOptions > 10 ? 10 : numOptions;
        for (let i = 1; i <= numb; i++) {
          var option = document.createElement("option");
          option.value = i;
          option.innerHTML = i;
          selectElement.appendChild(option);
        }
        handleChange(1);
      }


      getLocations();

      if (localStorage.getItem("orderNumber")){
        localStorage.removeItem("orderNumber")
      }

    }, []);
      
    
    return (
      <div>
      <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
      }}
      >
      <form>
        <select id="locationSelect">
        </select>
      </form>
      </div>
      <br></br>
      <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
      }}
      >
      
      
      <form >
      <p style = {{marginBottom:"12px", marginTop:"0px"}}>Select Firewood Bundle Quantity</p>
        <select id="numBagSelector" >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
      </form>
      </div>
      </div>
    );

  }
  
  export default LocationQuantitySelection;
 
 