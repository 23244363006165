import React from "react";
import OrderConfirm from "../components/OrderConfirm";

const API_URL = process.env.REACT_APP_API;

const orderConfirmation = () => {

    return (
      <OrderConfirm/>
    );
  };
  
  export default orderConfirmation;