import React from "react";
import { Container,Row,Col,Image } from "react-bootstrap";
import './contact.css';

  
const Contact = () => {
  return (
    <h1>Contact</h1>
  );
};
  
export default Contact;