import React from "react";
import {useEffect} from "react";
import {Card,ListGroup,Form,Button} from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { payments } from '@square/web-sdk';
const API_URL = process.env.REACT_APP_API;

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;

const OrderConfirm = () => {
  const navigate = useNavigate();

  useEffect(() => {

    async function getOrder() {
      var customerInfo = localStorage.getItem("email");

      if (localStorage.getItem("phone") && localStorage.getItem("phone") !== ""){
        customerInfo += "<br>" + localStorage.getItem("phone");
      }

      document.getElementById("info").innerHTML = customerInfo;

      document.getElementById("pickup").innerHTML = localStorage.getItem("address");
      
      document.getElementById("numBundles").innerHTML = localStorage.getItem("quantity");
      document.getElementById("paymentMethod").innerHTML = localStorage.getItem("paymentType");
      var total = localStorage.getItem("total");
      document.getElementById("totalPrice").innerHTML = "$" + total;
    }

    async function addOrder() {
      if (emailRegex.test(localStorage.getItem("email")) && (!localStorage.getItem("phone") || localStorage.getItem("phone").length === 0 || phoneRegex.test(localStorage.getItem("phone")))) {
        const response = await fetch(`${API_URL}/addOrder`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            date: Date.now(),
            location: localStorage.getItem("location"),
            address: localStorage.getItem("address"),
            email: localStorage.getItem("email"),
            phoneNumber: localStorage.getItem("phone"),
            quantity: localStorage.getItem("quantity"),
            paymentType: localStorage.getItem("paymentType")
          })
        }).catch((error) => {
          return console.log(error);
        });

        if (!response || !response.ok) {
          return console.log("Bad response / no response");
        }
        
        const data = await response.json();
        //console.log(data);
        localStorage.setItem("orderNumber", data.idNumber)
        var phone = -1;
        if (data.phoneNumber !== null && data.phoneNumber !== "") {
          var phoneValue = data.phoneNumber;
          phoneValue = phoneValue.replace(/[^0-9]/g, '');
          phone = parseInt(phoneValue);
          sendText(data.idNumber, data.price.toFixed(2), data.quantity, data.paymentType, data.email, phone, data.address);
        }
        sendEmail(data.idNumber, data.price.toFixed(2), data.quantity, data.paymentType, data.email, phone, data.address);
        navigate("../orderPlaced")
      } else {
        alert("Localstorage email / phone changed and is no longer valid. Order could not be placed.");
      }
    }

    async function sendEmail(idNumber, price, quantity, paymentType, email, phone, address) {
      try {
        var customerInfo = "";
        customerInfo += email;
        if (phone !== -1){
          customerInfo += " (" + phone + ")";
        }

        var payStatusMsgForAdmin = "";
        if (paymentType === "Square"){
          payStatusMsgForAdmin = "The customer has paid via Square.";
        } else if(paymentType === "e-Transfer"){
          payStatusMsgForAdmin = "The customer will pay via e-Transfer.";
        } else if(paymentType === "Cash"){
          payStatusMsgForAdmin = "The customer will pay you cash.";
        }

        const response = await fetch(`${API_URL}/send_mail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            type: "user",
            email: email,
            address: address,
            customerBody: "**** Please do not reply to this email. Should you have any queries, please email us on woodkelowna@gmail.com" +
                          "<br>Thank you for your order of " + quantity + " bags of firewood, your total purchase amount was $" + price + " and your order id number is " + idNumber + "." +
                          "<br>Please do not go to the pickup location (" + address + ") until you have heard from our representative that your order is ready for pickup. Should you not get a prompt reply feel free to email us at woodkelowna@gmail.com" +
                          "<br>On behalf of the Rotary Club of Kelowna Ogopogo and Camp OAC, thank you for your support.",
            adminBody: "An order has been placed by " + customerInfo + " for " + quantity + " bags of firewood at " + address + ". " + payStatusMsgForAdmin +
                        "<br>Please contact the customer by email/text to arrange for a pickup. The customer's order id number is " + idNumber + "."
          })
        }).catch((error) => {
          return console.log(error);
        });

        if (!response || !response.ok) {
          return console.log("Bad response / no response");
        }

        const data = await response.json();
        //console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    async function sendText(idNumber, price, quantity, paymentType, email, phone, address) {
      try {
        var customerInfo = "";
        customerInfo += email;
        if (phone !== -1){
          customerInfo += " (" + phone + ")";
        }

        var payStatusMsgForAdmin = "";
        if (paymentType === "Square"){
          payStatusMsgForAdmin = "The customer has paid via Square.";
        } else if(paymentType === "e-Transfer"){
          payStatusMsgForAdmin = "The customer will pay via e-Transfer.";
        } else if(paymentType === "Cash"){
          payStatusMsgForAdmin = "The customer will pay you cash.";
        }

        const response = await fetch(`${API_URL}/messages`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
              type: "user",
              to: phone,
              address: address,
              customerBody: "**** Please do not reply to this text. Should you have any queries, please email us on woodkelowna@gmail.com" +
                            "\nThank you for your order of " + quantity + " bags of firewood, your total purchase amount was $" + price + " and your order id number is " + idNumber + "." +
                            "\nPlease do not go to the pickup location (" + address + ") until you have heard from our representative that your order is ready for pickup. Should you not get a prompt reply feel free to email us at woodkelowna@gmail.com" +
                            "\nOn behalf of the Rotary Club of Kelowna Ogopogo and Camp OAC, thank you for your support.",
              adminBody: "An order has been placed by " + customerInfo + " for " + quantity + " bags of firewood at " + address + ". " + payStatusMsgForAdmin +
                          "\nPlease contact the customer by email/text to arrange for a pickup. The customer's order id number is " + idNumber + "."
          })
        }).catch((error) => {
          return console.log(error)
        })

        if (!response || !response.ok){
            return console.log('Bad response / no response')
        }
        const data = await response.json();
        //console.log(data, "TWILIO TEST");
      } catch (error) {
        console.log(error);
      }
    }



    async function SquareForm(){
      // Store your Square application ID
      // The application ID is automatically included in your checkout token response under the `gateways` key
      const appId = process.env.REACT_APP_SQUARE_APPLICATION_ID//'sandbox-sq0idb-HcytOQpgWC2Jv3r_zkmnRQ';
      const locationId = process.env.REACT_APP_SQUARE_LOCATION_ID//'LPHKRTE9BW7FZ';
  
      // Initialize the Square Payments object with your application ID and the ID of the seller location where the payment is taken once the `Square` script is loaded.
      // We will use this object to instantiate `Payment` methods later on
      const squarePayments = await payments(appId, locationId);
  
      // First define your optional card styles
      // https://developer.squareup.com/docs/web-payments/customize-styles#step-2-define-a-custom-style
      const cardOptions = {
        // ...
      }
  
      // Then create a function to initialize and mount the Card payment form object
      // https://developer.squareup.com/reference/sdks/web/payments/objects/Card
  
      // Creates a new Card instance with all of the behaviors and properties needed to take a card payment.
      const card = await squarePayments.card({
        // Pass in card options styles
        style: cardOptions,
      });
      // Attach the Card instance to the empty DOM element defined in the above HTML code snippet. The payment card form renders into the empty element, turning it into a secure payment card form.
      await card.attach('#card-container'); 
      const cardButton = document.getElementById('card-button');
      cardButton.removeAttribute("hidden");
      cardButton.addEventListener('click', async () => {
        cardButton.disabled = true;
        const statusContainer = document.getElementById('payment-status-container');
  
        try {
          const result = await card.tokenize();
          if (result.status === 'OK') {
            //console.log(`Payment token is ${result.token}`);
            statusContainer.innerHTML = "Token Successful";
            charge(result.token, statusContainer);
  
          } else {
            let errorMessage = `Tokenization failed with status: ${result.status}`;
            if (result.errors) {
              errorMessage += ` and errors: ${JSON.stringify(
                result.errors
              )}`;
            }
            cardButton.disabled = false;
            throw new Error(errorMessage);
          }
        } catch (e) {
          console.error(e);
          cardButton.disabled = false;
          statusContainer.innerHTML = "Token Failed";
        }
      });
    }
  
    async function charge(token, statusContainer){
      const response = await fetch(`${API_URL}/charge`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              //'Authorization': localStorage.getItem('token')
              //"Access-Control-Allow-Origin": "http://localhost:3000",
              //"Access-Control-Allow-Credentials": "true",
          },
          body: JSON.stringify({
              token: token,
              numBags: localStorage.getItem("quantity")
          })
          //credentials: 'include'
      }).catch((error) => {
          return console.log(error)
      })
      
      const data = await response.json();
  
  // View the payment data for bug testing
      //console.log(data);
  
  // Check whether payment was successful or not
      async function statusCheck(onSuccess) {
        if (data.errors && data.errors.length > 0) {
          if (data.errors[0].detail) {
            alert(data.errors[0].detail);
          } else {
            statusContainer.innerHTML = "Payment Failed";
            alert('Payment Failed.');
          }
        } else {
          statusContainer.innerHTML = "Payment Success";
          //alert('Payment Success.');
          addOrder();
        }
        document.getElementById('card-button').disabled = false;
      }
      statusCheck();
    }
  
    getOrder();
    if (localStorage.getItem("paymentType") && localStorage.getItem("paymentType") === "Square"){
      document.getElementById("formButton").setAttribute("hidden", "hidden");
      SquareForm();
    } else {
      document.getElementById("formButton").addEventListener("click", function(event){
        event.preventDefault();
        document.getElementById("formButton").disabled = true;
        addOrder();
      });
    }
}, []);

    return (
      <div
      className="flex-container"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop:'25px',
        marginBottom:'25px'
      }}
    >
      <div style={{ display:'flex',alignItems: 'center', justifyContent: "center"}}>
      
      <Card classname = "my-6 mx-auto" style={{ maxWidth: '95vw'}} align="center" >
      <Form>
      <Card.Header><p style={{fontSize:'25px'}}>Order Confirmation</p></Card.Header>
      <Card.Body>
      <ListGroup className="list-group-flush" style={{fontSize:'18px',fontWeight:"bold"}}>
          <ListGroup.Item style={{padding:'10px'}}>
            <p>Information: <p id = "info" style={{fontWeight:"normal"}}></p></p>
            </ListGroup.Item>
            <ListGroup.Item style={{padding:'10px'}}>
            <p>Pickup Location:<p id = "pickup" style={{fontWeight:"normal"}}></p></p>
            </ListGroup.Item>
          <ListGroup.Item style={{padding:'10px'}}>
            <p>Quantity:<p id = "numBundles" style={{fontWeight:"normal"}}></p></p>
            </ListGroup.Item>
          <ListGroup.Item style={{padding:'10px'}}>
            <p>Payment Method:<p id = "paymentMethod" style={{fontWeight:"normal"}}></p></p>
            </ListGroup.Item>
          <ListGroup.Item style={{padding:'10px'}}>
            <p>Total Price:<p id = "totalPrice" style={{fontWeight:"normal"}}></p></p>
          </ListGroup.Item>
          <div id="card-container"></div>
          <Button variant="primary" id="card-button" hidden> Pay and Place Order </Button>
          <p id="payment-status-container"></p>
        </ListGroup>
        <Button id="formButton" type="submit">
            Place Order
          </Button>
      </Card.Body>
      </Form>
      <Card.Img variant="top" src="Firewoodbundles2.jpg" style={{ maxWidth:'400px'}}/>
      </Card>
      </div>
    </div>
    );
  };
  
  export default OrderConfirm;