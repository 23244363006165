import React from "react";
import {Button, Card, Table} from 'react-bootstrap';
import {useEffect,useState } from "react";
import {useNavigate} from 'react-router-dom';
import './styles.css';

const API_URL = process.env.REACT_APP_API;

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;

const SuperAdminSummary = () => {

    const navigate = useNavigate();
    const priceHeaderNBSPs = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";

    useEffect(() => {

        if (!localStorage.getItem('type') || localStorage.getItem('type') !== 'superadmin'){
            document.getElementById('adminSummaryCard').setAttribute("hidden", "hidden");
            document.getElementById('bagPriceCard').setAttribute("hidden", "hidden");
            document.getElementById('homepageDetailsCard').setAttribute("hidden", "hidden");
            document.getElementById('selectionDetailsCard').setAttribute("hidden", "hidden");
            document.getElementById('contactDetailsCard').setAttribute("hidden", "hidden");
        }
        if (localStorage.getItem('type') && localStorage.getItem('type') === 'superadmin'){
        var adminUsernames = [];
        var adminEmails = [];
        var adminPhones = [];

        var addAdminButton = document.getElementById("addAdminButton");
        addAdminButton.onmouseover = function(){
            addAdminButton.style.background = "#d6d6d6";
        }
        addAdminButton.onmouseout = function(){
            addAdminButton.style.background = "#f0f0f0";
        }

        async function getAdminList() {

            if (localStorage.getItem('token') === null){
                return console.log('No Token')
            }

            const response = await fetch(`${API_URL}/getAdminList`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                    //"Access-Control-Allow-Origin": "http://localhost:3000",
                    //"Access-Control-Allow-Credentials": "true",
                }
                //credentials: 'include'
              }).catch((error) => {
                return console.log(error)
              })

            if (!response || !response.ok){
                return console.log('Bad response / no response')
            }
            const data = await response.json();
            console.log(data);

            data[1].forEach(element => {
                insertRow(element, data[0]);
            });
        }


        async function updateAdmin(username, statusVal, emailNotifsEnabled, phoneNotifsEnabled) {
            if (localStorage.getItem('token') === null){
                return console.log('No Token')
            }

            const response = await fetch(`${API_URL}/updateAdmin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                    //"Access-Control-Allow-Origin": "http://localhost:3000",
                    //"Access-Control-Allow-Credentials": "true",
                },
                body: JSON.stringify({
                    'username': username,
                    'status': statusVal,
                    'emailNotifsEnabled': emailNotifsEnabled,
                    'phoneNotifsEnabled': phoneNotifsEnabled
                })
            })

            if (!response || !response.ok){
                console.log('Bad response / no response');
                return navigate("../admin");
            }

            const data = await response.json();
            console.log(data);
        }


        async function addAdmin(username, password, type, email, phone) {
            if (localStorage.getItem('token') === null){
                return console.log('No Token')
            }

            addAdminButton.disabled = true;

            const response = await fetch(`${API_URL}/addAdmin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                    //"Access-Control-Allow-Origin": "http://localhost:3000",
                    //"Access-Control-Allow-Credentials": "true",
                },
                body: JSON.stringify({
                    'username': username,
                    'password': password,
                    'type': type,
                    'email': email,
                    'phone': phone
                })
            })

            if (!response || !response.ok){
                console.log('Bad response / no response');
                return navigate("../admin");
            }

            const data = await response.json();
            console.log(data);

            document.getElementById("addAdminUsernameInput").value = "";
            document.getElementById("addAdminEmailInput").value = "";
            document.getElementById("addAdminPhoneInput").value = "";
            document.getElementById("addAdminPasswordInput").value = "";
            insertRow(data);

            if (document.getElementsByClassName("locationManagerDropdown")){
                const locationManagerDropdowns = document.getElementsByClassName("locationManagerDropdown");
                for (let i = 0; i < locationManagerDropdowns.length; i++) {
                    const dropOption = document.createElement('option');
                    dropOption.value = data.username;
                    dropOption.innerHTML = data.username;
                    locationManagerDropdowns[i].appendChild(dropOption);
                }
            }

            addAdminButton.disabled = false;
        }

        function insertRow(element, currentAdminUsername){
            const table = document.getElementById('adminListTable');
            const tr = document.createElement('tr');
            const username = document.createElement('td');
            const email = document.createElement('td');
            const phone = document.createElement('td');
            const adminType = document.createElement('td');
            const statusCell = document.createElement('td');
            const notifCell = document.createElement('td');

            // Email notification checkbox
            const emailNotifDiv = document.createElement('div');
            const emailNotifCheckbox = document.createElement('input');
            emailNotifCheckbox.setAttribute("type", "checkbox");
            if (element.emailNotifsEnabled === true) {
                emailNotifCheckbox.setAttribute("checked", "checked");
            }
            emailNotifCheckbox.setAttribute("id", "emailNotifCheckbox_" + element.username);
            const emailNotifLabel = document.createElement('label');
            emailNotifLabel.innerHTML = "Enable email notifications";
            emailNotifLabel.setAttribute("for", "emailNotifCheckbox_" + element.username);
            notifCell.appendChild(emailNotifDiv);
            emailNotifDiv.appendChild(emailNotifCheckbox);
            emailNotifDiv.appendChild(emailNotifLabel);
            emailNotifCheckbox.addEventListener("change", function (event) {
                if (emailNotifCheckbox.checked) {
                    updateAdmin(element.username, null, true, null)
                } else {
                    updateAdmin(element.username, null, false, null)
                }
            });

            // Phone notification checkbox
            const phoneNotifDiv = document.createElement('div');
            const phoneNotifCheckbox = document.createElement('input');
            phoneNotifCheckbox.setAttribute("type", "checkbox");
            if (element.phoneNotifsEnabled === true) {
                phoneNotifCheckbox.setAttribute("checked", "checked");
            }
            phoneNotifCheckbox.setAttribute("id", "phoneNotifCheckbox_" + element.username);
            const phoneNotifLabel = document.createElement('label');
            phoneNotifLabel.innerHTML = "Enable text notifications";
            phoneNotifLabel.setAttribute("for", "phoneNotifCheckbox_" + element.username);
            notifCell.appendChild(phoneNotifDiv);
            phoneNotifDiv.appendChild(phoneNotifCheckbox);
            phoneNotifDiv.appendChild(phoneNotifLabel);
            phoneNotifCheckbox.addEventListener("change", function (event) {
                if (phoneNotifCheckbox.checked) {
                    updateAdmin(element.username, null, null, true)
                } else {
                    updateAdmin(element.username, null, null, false)
                }
            });


            if (element.username !== currentAdminUsername){
                const statusSelect = document.createElement("select");
                const activeOption = document.createElement("option");
                activeOption.value = "active";
                activeOption.innerHTML = "Active";
                const inactiveOption = document.createElement("option");
                inactiveOption.value = "inactive";
                inactiveOption.innerHTML = "Inactive";

                statusSelect.appendChild(activeOption);
                statusSelect.appendChild(inactiveOption);
                statusCell.appendChild(statusSelect);

                statusSelect.addEventListener("change", function(event){
                    event.preventDefault();
                    updateAdmin(element.username, event.target.value, null, null);
                })

                statusSelect.value = element.status;
            }

            username.innerHTML = element.username;
            email.innerHTML = element.email;
            var phoneVal = element.phone === -1 ? "" : element.phone.toString();
            phoneVal = phoneVal.substring(0,3) + "-" + phoneVal.substring(3,6) + "-" + phoneVal.substring(6,10);
            phone.innerHTML = phoneVal;
            adminType.innerHTML = element.type;
            tr.appendChild(username);
            tr.appendChild(email);
            tr.appendChild(phone);
            tr.appendChild(adminType);
            tr.appendChild(statusCell);
            tr.appendChild(notifCell);
            table.appendChild(tr);
            adminUsernames.push(element.username);
            adminEmails.push(element.email);
            adminPhones.push(element.phone);
        }


        document.getElementById("addAdminForm").addEventListener("submit", function(event){
            event.preventDefault();
            const inputtedUsername = document.getElementById("addAdminUsernameInput").value;
            var inputtedEmail = document.getElementById("addAdminEmailInput").value;
            var inputtedPhone = document.getElementById("addAdminPhoneInput").value;
            var rawPhone = inputtedPhone.replace(/[^0-9]/g, '');
            rawPhone = parseInt(rawPhone);
            const inputtedPassword = document.getElementById("addAdminPasswordInput").value;
            const selectedAdminType = document.getElementById("addAdminTypeSelect").value;
            //check if username doesnt already exist
            if (inputtedUsername !== "" && inputtedPassword !== "" && !adminUsernames.includes(inputtedUsername)){
                // If valid email wasn't inputted
                if (adminEmails.includes(inputtedEmail) || !emailRegex.test(inputtedEmail)) {
                    inputtedEmail = "";
                }
                // If valid phone wasn't inputted
                if (adminPhones.includes(rawPhone) || !phoneRegex.test(inputtedPhone)) {
                    inputtedPhone = -1;
                } else {
                    inputtedPhone = rawPhone;
                }

                addAdmin(inputtedUsername, inputtedPassword, selectedAdminType, inputtedEmail, inputtedPhone);
            } else {
                console.log("Admin username is invalid");
            }
        });

        async function getPriceAndDiscount() {
            //PRICE
            const response = await fetch(`${API_URL}/getPrice`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
                //credentials: 'include'
              }).catch((error) => {
                return console.log(error)
              })

            if (!response || !response.ok){
                return console.log('Bad response / no response')
            }
            const data = await response.json();
            console.log(data);

            var tempPriceHeaderString = 'Bag Price: <b>$' + data[0].price.toFixed(2) + '</b>';
            document.getElementById('bagPriceInput').value = data[0].price.toFixed(2);


            //DISCOUNT
            const response2 = await fetch(`${API_URL}/getDiscount`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
                //credentials: 'include'
              }).catch((error) => {
                return console.log(error)
              })

            if (!response2 || !response2.ok){
                return console.log('Bad response / no response')
            }
            const data2 = await response2.json();
            console.log(data2);

            var discountPrice = 0;
            var discountQuantity = 1;
            data2.forEach(element => {
                if (element.type === "discountPrice"){
                    discountPrice = element.price;
                }
                if (element.type === "discountQuantity"){
                    discountQuantity = element.price;
                }
            });

            if (discountPrice !== -1){
                document.getElementById('discountAmtInput').value = discountPrice;
            }
            if (discountQuantity !== -1){
                document.getElementById('discountQtyInput').value = discountQuantity;
            }

            document.getElementById('bagPriceHeader').innerHTML = tempPriceHeaderString + priceHeaderNBSPs + 'Discount: <b>$' + discountPrice.toFixed(2) + ' off per ' + discountQuantity + ' bags</b>';
        }

        async function getPriceHistory() {
            const response = await fetch(`${API_URL}/getPriceHistory`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
                //credentials: 'include'
              }).catch((error) => {
                return console.log(error)
              })

            if (!response || !response.ok){
                return console.log('Bad response / no response')
            }
            const data = await response.json();
            console.log(data);

            var priceHistoryString = "";
            var lastDiscountQty = -1; // kinda cursed but should be fine. relies on the fact that disount quantity is saved after discount price and that we traverse the database backwards
            data.forEach(element => {
                var parsedDate = parseDate(element.dateUpdated);
                if (element.type === "price"){
                    priceHistoryString += parsedDate + " " + element.updatedBy + " changed price to $" + element.price.toFixed(2) + "\n";
                }
                if (element.type === "discountPrice"){
                    priceHistoryString += parsedDate + " " + element.updatedBy + " changed discount to $" + element.price.toFixed(2) + " off per " + lastDiscountQty + " bags\n";
                }
                if (element.type === "discountQuantity"){
                    lastDiscountQty = element.price;
                }
            });

            document.getElementById('priceHistory').value = priceHistoryString;
        }


        async function updateBagPrice(price) {
            const response = await fetch(`${API_URL}/updatePrice`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                    //"Access-Control-Allow-Origin": "http://localhost:3000",
                    //"Access-Control-Allow-Credentials": "true",
                },
                body: JSON.stringify({
                    'price': price
                })
              })

            if (!response || !response.ok){
                console.log('Bad response / no response');
                return navigate("../admin");
            }
            const data = await response.json();
            console.log(data);

            var priceHeader = document.getElementById('bagPriceHeader');
            var priceHeaderDiscountString = priceHeader.innerHTML.substring(priceHeader.innerHTML.indexOf('Discount'));
            priceHeader.innerHTML = 'Bag Price: <b>$' + data.price.toFixed(2) + '</b>' + priceHeaderNBSPs + priceHeaderDiscountString;

            document.getElementById('bagPriceInput').value = data.price.toFixed(2);
            document.getElementById('bagPriceButton').disabled = true;

            var parsedDate = parseDate(data.dateUpdated);
            var newPriceHistory = parsedDate + " " + data.updatedBy + " changed price to $" + data.price.toFixed(2) + "\n";
            newPriceHistory += document.getElementById('priceHistory').value;
            document.getElementById('priceHistory').value = newPriceHistory;
        }

        async function updateDiscount(price, quantity) {
            const response = await fetch(`${API_URL}/updateDiscount`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                    //"Access-Control-Allow-Origin": "http://localhost:3000",
                    //"Access-Control-Allow-Credentials": "true",
                },
                body: JSON.stringify({
                    'discountPrice': price,
                    'discountQuantity': quantity
                })
              })

            if (!response || !response.ok){
                console.log('Bad response / no response');
                return navigate("../admin");
            }
            const data = await response.json();
            console.log(data);

            var priceHeader = document.getElementById('bagPriceHeader');
            var priceHeaderString = priceHeader.innerHTML.substring(0, priceHeader.innerHTML.indexOf('Discount'));
            priceHeaderString += 'Discount: <b>$' + data.discountPrice.price.toFixed(2) + ' off per ' + data.discountQuantity.price + ' bags</b>'
            priceHeader.innerHTML = priceHeaderString;

            document.getElementById('discountAmtInput').value = data.discountPrice.price;
            document.getElementById('discountQtyInput').value = data.discountQuantity.price;
            document.getElementById('discountSaveButton').disabled = true;

            var parsedDate = parseDate(data.discountPrice.dateUpdated);
            var newPriceHistory = parsedDate + " " + data.discountPrice.updatedBy + " changed discount to $" + data.discountPrice.price.toFixed(2) + " off per " + data.discountQuantity.price + " bags\n";
            newPriceHistory += document.getElementById('priceHistory').value;
            document.getElementById('priceHistory').value = newPriceHistory;
        }

        function parseDate(rawDate) {
            const dateString = new Date(rawDate).toString();
            return dateString.substring(8,10) + '-' + dateString.substring(4,7) + '-' + dateString.substring(11,15);
        }
        

        document.getElementById("bagPriceForm").addEventListener("submit", function(event){
            event.preventDefault();
            const inputtedPrice = parseFloat(document.getElementById("bagPriceInput").value);
            updateBagPrice(inputtedPrice)
        });
        
        document.getElementById("bagPriceForm").addEventListener("input", function(event){
            event.preventDefault();
            if (event.target.value === ""){
                document.getElementById('bagPriceButton').disabled = true;
            } else {
                document.getElementById('bagPriceButton').disabled = false;
            }
        });

        document.getElementById("discountForm").addEventListener("submit", function(event){
            event.preventDefault();
            const inputtedPrice = parseFloat(document.getElementById("discountAmtInput").value);
            const inputtedQuantity = parseInt(document.getElementById("discountQtyInput").value);
            updateDiscount(inputtedPrice, inputtedQuantity)
        });

        document.getElementById("discountForm").addEventListener("input", function(event){
            event.preventDefault();
            if (event.target.value === ""){
                document.getElementById('discountSaveButton').disabled = true;
            } else {
                document.getElementById('discountSaveButton').disabled = false;
            }
        });


        async function getHomePageInfo(){
            const response = await fetch(`${API_URL}/getHomePageInfo`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    //'Authorization': localStorage.getItem('token')
                }
            });
        
            if (!response || !response.ok){
                return console.log('Bad response / no response')
            }
            console.log(response);
            const data = await response.json();
            console.log(data);
        
            data.forEach(element => {
                if (element.type === "title"){
                    document.getElementById("titleInput").value = element.value;
                } else if (element.type === "description"){
                    document.getElementById("descInput").value = element.value;
                } else if (element.type === "woodDescription"){
                    document.getElementById("woodDescInput").value = element.value;
                } else if (element.type === "rotaryContact"){
                    document.getElementById("rotaryContactInput").value = element.value;
                } else if (element.type === "campOacContact"){
                    document.getElementById("campOacContactInput").value = element.value;
                }
            });
        }

        async function updateHomePageInfo(infoType, val) {
            const response = await fetch(`${API_URL}/updateHomePageInfo`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                    //"Access-Control-Allow-Origin": "http://localhost:3000",
                    //"Access-Control-Allow-Credentials": "true",
                },
                body: JSON.stringify({
                    'type': infoType,
                    'value': val
                })
            })

            if (!response || !response.ok){
                console.log('Bad response / no response');
                return navigate("../admin");
            }
            const data = await response.json();
            console.log(data);

            if (infoType === "title"){
                document.getElementById('homeTitleButton').disabled = true;
            } else if (infoType === "description"){
                document.getElementById('homeDescButton').disabled = true;
            } else if (infoType === "woodDescription"){
                document.getElementById('woodDescButton').disabled = true;
            } else if (infoType === "rotaryContact"){
                document.getElementById('rotaryContactButton').disabled = true;
            } else if (infoType === "campOacContact"){
                document.getElementById('campOacContactButton').disabled = true;
            }
            
        }

        document.getElementById("homeTitleForm").addEventListener("submit", function(event){
            event.preventDefault();
            updateHomePageInfo("title", document.getElementById("titleInput").value);
        });
        
        document.getElementById("homeTitleForm").addEventListener("input", function(event){
            event.preventDefault();
            document.getElementById('homeTitleButton').disabled = false;
        });

        document.getElementById("homeDescForm").addEventListener("submit", function(event){
            event.preventDefault();
            updateHomePageInfo("description", document.getElementById("descInput").value);
        });
        
        document.getElementById("homeDescForm").addEventListener("input", function(event){
            event.preventDefault();
            document.getElementById('homeDescButton').disabled = false;
        });

        document.getElementById("woodDescForm").addEventListener("submit", function(event){
            event.preventDefault();
            updateHomePageInfo("woodDescription", document.getElementById("woodDescInput").value);
        });
        
        document.getElementById("woodDescForm").addEventListener("input", function(event){
            event.preventDefault();
            document.getElementById('woodDescButton').disabled = false;
        });

        document.getElementById("rotaryContactForm").addEventListener("submit", function(event){
            event.preventDefault();
            updateHomePageInfo("rotaryContact", document.getElementById("rotaryContactInput").value);
        });
        
        document.getElementById("rotaryContactForm").addEventListener("input", function(event){
            event.preventDefault();
            document.getElementById('rotaryContactButton').disabled = false;
        });

        document.getElementById("rotaryContactForm").addEventListener("submit", function(event){
            event.preventDefault();
            updateHomePageInfo("rotaryContact", document.getElementById("rotaryContactInput").value);
        });
        
        document.getElementById("rotaryContactForm").addEventListener("input", function(event){
            event.preventDefault();
            document.getElementById('rotaryContactButton').disabled = false;
        });

        document.getElementById("campOacContactForm").addEventListener("submit", function(event){
            event.preventDefault();
            updateHomePageInfo("campOacContact", document.getElementById("campOacContactInput").value);
        });
        
        document.getElementById("campOacContactForm").addEventListener("input", function(event){
            event.preventDefault();
            document.getElementById('campOacContactButton').disabled = false;
        });


        getAdminList();
        getPriceAndDiscount();
        getPriceHistory();
        getHomePageInfo();

        }
    }, []);

    return(
    <div>
    <Card id='bagPriceCard' style={{ width: '30rem' }}>
    <Card.Header id='bagPriceHeader'></Card.Header>
    <Card.Body>
    <Table bordered fluid>
        <thead>
        <tr>
        <th>
            <form id="bagPriceForm">
                <input class="bagPriceInput" id="bagPriceInput" type="number" min="0" step="any"></input>
                <button class="bagPriceButton" id="bagPriceButton" type="submit" disabled="true">Save</button>
            </form>
            <br></br>
            <form id="discountForm">
                $&nbsp;
                <input class="discountInput" id="discountAmtInput" type="number" min="0" step="any"></input>
                &nbsp;off for every&nbsp;
                <input class="discountInput" id="discountQtyInput" type="number" min="0" step="1"></input>
                &nbsp;bags&nbsp;&nbsp;&nbsp;&nbsp;
                <button class="discountSaveButton" id="discountSaveButton" type="submit" disabled="true">Save</button>
            </form>
            <br></br>
            <textarea id="priceHistory" type="text" rows="4" cols="55" disabled></textarea>
        </th>
        </tr>
        </thead>
    </Table>
    </Card.Body>
    </Card>

    <Card id='homepageDetailsCard' style={{ width: '30rem' }}>
    <Card.Header id='homepageDetailsHeader'>Home Page Details</Card.Header>
    <Card.Body>
    <Table bordered fluid>
        <thead>
        <tr>
            <th>
                <form class="homeTitleForm" id="homeTitleForm">
                    <label>Title:</label>
                    <textarea id="titleInput" type="text" rows="2" cols="33"></textarea>
                    <button id="homeTitleButton" class="homeTitleButton" type="submit" disabled="true">Save</button>
                </form>
            </th>
        </tr>
        <tr>
            <th>
                <form class="homeDescForm" id="homeDescForm">
                    <label>Description:</label>
                    <textarea id="descInput" type="text" rows="5" cols="33"></textarea>
                    <button id="homeDescButton" class="homeDescButton" type="submit" disabled="true">Save</button>
                </form>
            </th>
        </tr>
        </thead>
    </Table>
    </Card.Body>
    </Card>

    <Card id='selectionDetailsCard' style={{ width: '30rem' }}>
    <Card.Header id='homepageDetailsHeader'>Selection Page Details</Card.Header>
    <Card.Body>
    <Table bordered fluid>
        <thead>
        <tr>
            <th>
                <form class="woodDescForm" id="woodDescForm">
                    <label>Bag Description:</label>
                    <textarea id="woodDescInput" type="text" rows="2" cols="33"></textarea>
                    <button id="woodDescButton" class="woodDescButton" type="submit" disabled="true">Save</button>
                </form>
            </th>
        </tr>
        </thead>
    </Table>
    </Card.Body>
    </Card>

    <Card id='contactDetailsCard' style={{ width: '30rem' }}>
    <Card.Header id='contactDetailsHeader'>Contact Details</Card.Header>
    <Card.Body>
    <Table bordered fluid>
        <thead>
        <tr>
            <th>
                <form class="rotaryContactForm" id="rotaryContactForm">
                    <label>Rotary Club Details:</label>
                    <textarea id="rotaryContactInput" type="text" rows="7" cols="33"></textarea>
                    <button id="rotaryContactButton" class="rotaryContactButton" type="submit" disabled="true">Save</button>
                </form>
            </th>
        </tr>
        <tr>
            <th>
                <form class="campOacContactForm" id="campOacContactForm">
                    <label>Camp OAC Details:</label>
                    <textarea id="campOacContactInput" type="text" rows="6" cols="33"></textarea>
                    <button id="campOacContactButton" class="campOacContactButton" type="submit" disabled="true">Save</button>
                </form>
            </th>
        </tr>
        </thead>
    </Table>
    </Card.Body>
    </Card>

    <Card id='adminSummaryCard'>
    <Card.Header> Admins </Card.Header>
    <Card.Body>
    <Table bordered fluid>
        <thead>
        <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Type</th>
            <th>Status</th>
            <th>Notifications</th>
        </tr>
        </thead>
        <tbody id="adminListTable">
        </tbody>
    </Table>
    </Card.Body>
    <Card.Body id="addAdminCard" class="position-relative" style={{ width: '24rem' }}>
    <Table bordered fluid>
        <thead>
        <tr>
        <th>
            <form class="addAdminForm" id="addAdminForm">
                <label class="addAdminInput">Add New Admin:</label>
                <input class="addAdminInput" id="addAdminUsernameInput" type="text" placeholder="Username"></input>
                <input class="addAdminInput" id="addAdminEmailInput" type="text" placeholder="Email (optional)"></input>
                <input class="addAdminInput" id="addAdminPhoneInput" type="text" placeholder="Phone (optional)"></input>
                <input class="addAdminInput" id="addAdminPasswordInput" type="text" placeholder="Password"></input>
                <select class="addAdminInput" id="addAdminTypeSelect">
                    <option value="admin">Admin</option>
                    <option value="superadmin">Superadmin</option>
                </select>
                <button class="addAdminButton" id="addAdminButton" type="submit">ADD</button>
            </form>
        </th>
        </tr>
        </thead>
    </Table>
    </Card.Body>
    </Card>
    </div>
    )
};

export default SuperAdminSummary;