import React from "react";
import Inventory from "../components/Inventory";
import OrderSummary from "../components/OrderSummary";
import SuperAdminSummary from "../components/SuperAdminSummary"
import '../components/styles.css';
import {useEffect,useState } from "react";

function AdminOrders() {

  window.onscroll = function() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("backToTop").removeAttribute("hidden");
    }else{
      document.getElementById("backToTop").setAttribute("hidden", "hidden");
    }   
  };

  useEffect(() => {
    // Matching code to reset is in home and selection
    document.getElementById("adminPortalNavLink").setAttribute("hidden", "hidden");
    document.getElementById("invNavLink").removeAttribute("hidden");
    document.getElementById("ordersNavLink").removeAttribute("hidden");
    if (localStorage.getItem('type') && localStorage.getItem('type') === 'superadmin'){
      document.getElementById("detailsNavLink").removeAttribute("hidden");
      document.getElementById("adminsNavLink").removeAttribute("hidden");
    }
    
    const logoutButton = document.getElementById("logoutButton");
    logoutButton.removeAttribute("hidden");
  }, []);

  return (
  <div>
    <a id="backToTop" href="#top" hidden>Back to Top</a>
    <div className="inventory">
      <Inventory/>
    </div>

    <div className="orders">
      <OrderSummary/>
    </div>

    <div className="adminSummary">
      <SuperAdminSummary/>
    </div>

    
  </div>
  );
}

export default AdminOrders;