import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavigationBar from "./components/Navbar";
import Home from "./pages/home";
import Admin from "./pages/admin";
import Contact from "./pages/contact";
import AdminOrders from "./pages/adminOrders";
import OrderConfirmation from "./pages/orderConfirmation";
import Selection from "./pages/selection";
import Footer from "./components/Footer";
import OrderPlaced from "./pages/orderPlaced";
import Address from "./pages/address";
import "./App.css";

function App() {
  return (
    <div class="app">
      <BrowserRouter>
        {window.location.pathname !== "/orderPlaced" && <NavigationBar />}
        <div
          class="spacer"
          style={{
            height: "2x",
            wdith: "100%",
            backgroundColor: "#17458f",
          }}
        ></div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/selection" element={<Selection />} />
          <Route path="/adminOrders" element={<AdminOrders />} />
          <Route path="/orderPlaced" element={<OrderPlaced />} />
          <Route path="/address" element={<Address />} />
          <Route path="/orderConfirmation" element={<OrderConfirmation />} />
        </Routes>
        {window.location.pathname !== "/orderPlaced" && <Footer />}
      </BrowserRouter>
    </div>
  );
}
export default App;
