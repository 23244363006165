import React from "react";
import {useEffect, useState} from "react";
import EmailForm from '../components/EmailForm';
import { Container,Row,Col,Button,Carousel } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import './home.css';
const API_URL = process.env.REACT_APP_API;


const Home = () => {
    const [data, setData] = useState("No data :(");
  
  useEffect(() => {

    // Matching code to reset is in adminOrders
    // Duplicate code in selection
    document.getElementById("adminPortalNavLink").removeAttribute("hidden");
    document.getElementById("invNavLink").setAttribute("hidden", "hidden");
    document.getElementById("ordersNavLink").setAttribute("hidden", "hidden");
    document.getElementById("detailsNavLink").setAttribute("hidden", "hidden");
    document.getElementById("adminsNavLink").setAttribute("hidden", "hidden");

    async function getData() {
      const url = `${API_URL}/hello`;
      const response = await fetch(url);
      const data = await response.json();
      setData(data.msg);
    }

    async function getHomePageInfo(){
      const response = await fetch(`${API_URL}/getHomePageInfo`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              //'Authorization': localStorage.getItem('token')
          }
      });

      if (!response || !response.ok){
          return console.log('Bad response / no response')
      }
      //console.log(response);
      const data = await response.json();
      //console.log(data);

      data.forEach(element => {
        if (element.type === "title"){
          document.getElementById("titleLabel").innerHTML = element.value;
        }
        if (element.type === "description"){
          document.getElementById("descriptionLabel").innerHTML = element.value;
        }
      });
    }

    async function websiteHit(){
      const response = await fetch(`${API_URL}/websiteHit`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              //'Authorization': localStorage.getItem('token')
          }
      });

      if (!response || !response.ok){
          return console.log('Bad response / no response')
      }
      const data = await response.json();
      localStorage.setItem("hitTime", data.time);
    }

    getData();
    getHomePageInfo();
    if ( !localStorage.getItem("token") && (!localStorage.getItem("hitTime") || (Date.now() - localStorage.getItem("hitTime")) > 1000*60*60*12) ) { // Visit per customer expires in 12 hours
      websiteHit();
    }
    if (localStorage.getItem("orderNumber")){
      localStorage.removeItem("orderNumber")
    }
  }, []); 



  return (
    <div className = "home">
    <Container fluid>
      <Row>
        <Col md = {6} sm = {12} style = {{marginTop:'50px',marginBottom:'50px'}}>
            <div class = "writeup">
              <h1 id="titleLabel"></h1>
              <p id="descriptionLabel">
              </p>
              
          <Button as={Link} to="/selection" variant="primary" size="lg" style = {{width:'75%'}}> Order </Button>
          </div>
        </Col>
        <Col md = {6} sm = {12} className = "fwood">
        </Col>
      </Row>
    </Container>
    
  
    <div class = "picsContainer">
    <Container>
      <Row >
        <Col md={12}> 
        <Carousel className = "my-5 mx-auto">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="Slide2.jpg"
            alt="slide2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="Slide3.jpg"
            alt="slide3"
          />
        </Carousel.Item> 
        <Carousel.Item>
        <img
            className="d-block w-100"
            src="Slide4.jpg"
            alt="slide4"
          />
        </Carousel.Item> 
        <Carousel.Item>
        <img
            className="d-block w-100"
            src="Slide5.jpg"
            alt="slide5"
          />
        </Carousel.Item> 
        <Carousel.Item> 
        <img
            className="d-block w-100"
            src="Slide6.jpg"
            alt="slide6"
          />
        </Carousel.Item>
        </Carousel>
        </Col>
      </Row>
    </Container>
    </div>
  </div>
  );
};
  
export default Home;