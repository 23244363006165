import React from "react";
import {Button, Card, Table} from 'react-bootstrap';
import {useEffect, useState, useRef } from "react";
import {useNavigate} from 'react-router-dom';
import './styles.css';

import DateRangePicker from 'react-bootstrap-daterangepicker';
//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import Plotly from 'plotly.js-dist-min';

const API_URL = process.env.REACT_APP_API;

const OrderSummary = () => {
    
    const navigate = useNavigate();
    var startDate = 1672590568000;
    var endDate = Date.now();

    useEffect(() => {
        
        var existingOrderIDs = [];
        var pendingIDs = [];
        var unstuckOffset = 0;  // messy fix to a bug... the use of this variable will occur less frequently with higher chunk sizes
        const orderStatusCellIndex = 9;

        var searchValue = ""; // no search as default

        var locationSearch = "All";
        var addressSearch = "All";
        var paymentSearch = "All";
        var statusSearch = "All";
        var adminSearch = "All";

        var minPriceSearch = -1;
        var maxPriceSearch = -1;
        var idSearch = -1;

        var locationManagers = [];

        const loadMoreButton = document.createElement('button');
        loadMoreButton.setAttribute("id", "loadMoreButton")
        loadMoreButton.innerHTML = "Load More";
        loadMoreButton.onclick = function(){
            getData(false, false)
        }
        loadMoreButton.onmouseover = function(){
            loadMoreButton.style.background = "#d6d6d6";
        }
        loadMoreButton.onmouseout = function(){
            loadMoreButton.style.background = "#f0f0f0";
        }
        loadMoreButton.disabled = true;

        
        var csvText = '';
        async function getData(isFirstRequest, isSearchRequest, fromClearButton) {
            // get inventory for location managers
            if (isFirstRequest){
                if (localStorage.getItem('token') === null){
                    return console.log('No Token')
                }

                const responseINV = await fetch(`${API_URL}/getInventory`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': localStorage.getItem('token')
                    }
                    }).catch((error) => {
                    return console.log(error)
                    })

                if (!responseINV || !responseINV.ok){
                    return console.log('Bad response / no response')
                }
                const dataINV = await responseINV.json();
                console.log(dataINV);

                

                dataINV.forEach(element => {
                    locationManagers.push(element.address);
                    locationManagers.push(element.locationManagers);
                });
            }

            csvText = 'ID, DATE, LOCATION, ADDRESS, EMAIL, PHONE, BAGS, TOTAL, PAYMENT TYPE, ORDER STATUS, ADMIN UPDATES\r\n';
            loadMoreButton.disabled = true;
            console.log(existingOrderIDs);

            if (localStorage.getItem('token') === null){
                return console.log('No Token')
            }

            if (isSearchRequest && !isFirstRequest){
                searchValue = document.getElementById("orderSearchInput").value;
            }

            

            const response = await fetch(`${API_URL}/getOrders`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                    //"Access-Control-Allow-Origin": "http://localhost:3000",
                    //"Access-Control-Allow-Credentials": "true",
                },
                body: JSON.stringify({
                    'numOrdersLoaded': existingOrderIDs.length,
                    'isFirstRequest': isFirstRequest,
                    'searchValue': searchValue,
                    'startDate': startDate,
                    'endDate': endDate,
                    'minPrice': minPriceSearch,
                    'maxPrice': maxPriceSearch,
                    'id': idSearch, 
                    'dropdownValues': [locationSearch, addressSearch, paymentSearch, statusSearch, adminSearch],
                    'unstuckOffset': unstuckOffset
                })
            })

            if (!response || !response.ok){
                console.log('Bad response / no response');
                return navigate("../admin");
            }

            const data = await response.json();
            console.log(data);

            var table;
            if (isFirstRequest){
                table = document.getElementById('orderSummaryPendingTable');
            } else {
                if (searchUsed()){ // searched orders
                    table = document.getElementById('orderSummarySearchTable');
                    table.innerHTML = "";
                    document.getElementById("LoadMoreButtonContainer").style.display = "none";
                    document.getElementById("pendingOrderSummary").setAttribute("hidden", "hidden");
                    document.getElementById("completedOrderSummary").setAttribute("hidden", "hidden");
                    document.getElementById("searchSummary").removeAttribute("hidden");
                    document.getElementById("searchLabel").removeAttribute("hidden");
                    document.getElementById("pendingLabel").setAttribute("hidden", "hidden");
                    document.getElementById("completedLabel").setAttribute("hidden", "hidden");
                    document.getElementById("statHelpLabel").setAttribute("hidden", "hidden");
                    document.getElementById("searchParamsLabel").removeAttribute("hidden");
                    document.getElementById("statsToggleButton").removeAttribute("hidden");
                    // if (startDate !== 1672590568000 && (endDate - startDate)<=1000*60*60*24*27){ // hide graph if range <= 27 days
                    //     document.getElementById("monthPlot").setAttribute("hidden", "hidden");
                    // } else {
                    //     document.getElementById("monthPlot").removeAttribute("hidden");
                    // }
                    // if (addressSearch !== "All"){
                    //     document.getElementById("revenueAddressPlot").setAttribute("hidden", "hidden");
                    // }else{
                    //     document.getElementById("revenueAddressPlot").removeAttribute("hidden");
                    // }
                    if (!Object.keys(data).length){ // if no search results
                        const tr = document.createElement('tr');
                        tr.innerHTML = "NO RESULTS FOUND"
                        table.appendChild(tr);
                    }
                 } else {  // completed orders
                    table = document.getElementById('orderSummaryCompletedTable');
                    document.getElementById("LoadMoreButtonContainer").style.display = "block";
                    document.getElementById("pendingOrderSummary").removeAttribute("hidden");
                    document.getElementById("completedOrderSummary").removeAttribute("hidden");
                    document.getElementById("searchSummary").setAttribute("hidden", "hidden");
                    document.getElementById("searchLabel").setAttribute("hidden", "hidden");
                    document.getElementById("pendingLabel").removeAttribute("hidden");
                    document.getElementById("completedLabel").removeAttribute("hidden");
                    document.getElementById("stats").setAttribute("hidden", "hidden");
                    document.getElementById("statHelpLabel").removeAttribute("hidden");
                    document.getElementById("searchParamsLabel").setAttribute("hidden", "hidden");
                    document.getElementById("statsToggleButton").setAttribute("hidden", "hidden");
                    document.getElementById("csvButton").setAttribute("hidden", "hidden");
                    if (isSearchRequest){
                        loadMoreButton.disabled = false;
                        return
                    }
                }
            }

            if (localStorage.getItem('type') && localStorage.getItem('type') === 'superadmin'){
                document.getElementById("completedAdminColumnHeader").removeAttribute("hidden");
                document.getElementById("pendingAdminColumnHeader").removeAttribute("hidden");
                document.getElementById("searchAdminColumnHeader").removeAttribute("hidden");
            }

            var showStats = false;
            document.getElementById("statsToggleButton").addEventListener("click", function(event){
                event.preventDefault();
                if (showStats){
                    showStats = false;
                    document.getElementById("statsToggleButton").innerHTML = "Show Stats";
                    document.getElementById("stats").setAttribute("hidden", "hidden");
                } else {
                    showStats = true;
                    document.getElementById("statsToggleButton").innerHTML = "Hide Stats";
                    document.getElementById("stats").removeAttribute("hidden");
                }
            });
            

            if (fromClearButton){
                return loadMoreButton.disabled = false;
            }

            var numOrdersAlreadyLoaded = 0;
            var totalSum = 0;
            var bagSum = 0;
            var orderHours = {
                "6am": 0,
                "7am": 0,
                "8am": 0,
                "9am": 0,
                "10am": 0,
                "11am": 0,
                "12pm": 0,
                "1pm": 0,
                "2pm": 0,
                "3pm": 0,
                "4pm": 0,
                "5pm": 0,
                "6pm": 0,
                "7pm": 0,
                "8pm": 0,
                "9pm": 0
            }

            var hitHours = {
                "6am": 0,
                "7am": 0,
                "8am": 0,
                "9am": 0,
                "10am": 0,
                "11am": 0,
                "12pm": 0,
                "1pm": 0,
                "2pm": 0,
                "3pm": 0,
                "4pm": 0,
                "5pm": 0,
                "6pm": 0,
                "7pm": 0,
                "8pm": 0,
                "9pm": 0
            }

            var orderDays = {
                "Sun": 0,
                "Mon": 0,
                "Tue": 0,
                "Wed": 0,
                "Thu": 0,
                "Fri": 0,
                "Sat": 0,
            }

            var hitDays = {
                "Sun": 0,
                "Mon": 0,
                "Tue": 0,
                "Wed": 0,
                "Thu": 0,
                "Fri": 0,
                "Sat": 0,
            }

            var orderMonths = {
                "Jan": 0,
                "Feb": 0,
                "Mar": 0,
                "Apr": 0,
                "May": 0,
                "Jun": 0,
                "Jul": 0,
                "Aug": 0,
                "Sep": 0,
                "Oct": 0,
                "Nov": 0,
                "Dec": 0,
            }

            var hitMonths = {
                "Jan": 0,
                "Feb": 0,
                "Mar": 0,
                "Apr": 0,
                "May": 0,
                "Jun": 0,
                "Jul": 0,
                "Aug": 0,
                "Sep": 0,
                "Oct": 0,
                "Nov": 0,
                "Dec": 0,
            }

            var revenueAddresses = {};

            data.forEach(element => {
                if (existingOrderIDs.includes(element._id.toString()) || pendingIDs.includes(element._id.toString())){
                    numOrdersAlreadyLoaded += 1;
                }
                if ( (!existingOrderIDs.includes(element._id.toString()) && !pendingIDs.includes(element._id.toString())) || searchUsed()){
                    const tr = document.createElement('tr');
                    if (!searchUsed()){
                        tr.setAttribute('id', element._id.toString())
                    }
                    const idNumber = document.createElement('td');
                    const date = document.createElement('td');
                    const location = document.createElement('td');
                    const address = document.createElement('td');
                    const email = document.createElement('td');
                    const phoneNumber = document.createElement('td');
                    const quantity = document.createElement('td');
                    const price = document.createElement('td');
                    const paymentType = document.createElement('td');
                    const orderStatus = document.createElement('td');
                    const adminUpdates = document.createElement('td');
                    idNumber.innerHTML = element.idNumber;
                    csvText += `"${element.idNumber}", `;
                    const orderDate = new Date(element.date);
                    const parsedDate = parseDate(orderDate.toString());
                    date.innerHTML = parsedDate;
                    csvText += `${parsedDate}, `;
                    const timeOrdered = orderDate.toLocaleTimeString("us-PT");
                    const hourOrdered = timeOrdered.substring(0, timeOrdered.indexOf(":")) + timeOrdered.slice(-2).toLowerCase();
                    date.title = timeOrdered;
                    if (hourOrdered in orderHours){
                        orderHours[hourOrdered] = orderHours[hourOrdered] + 1; // increment hour count
                    }
                    const dayOrdered = orderDate.toString().substring(0,3);
                    if (dayOrdered in hitDays){
                        orderDays[dayOrdered] = orderDays[dayOrdered] + 1; // increment day count
                    }
                    const monthOrdered = orderDate.toString().substring(4,7);
                    if (monthOrdered in hitMonths){
                        orderMonths[monthOrdered] = orderMonths[monthOrdered] + 1; // increment month count
                    }

                    if (element.address !== ""){
                        if (element.address in revenueAddresses){
                            revenueAddresses[element.address] = revenueAddresses[element.address] + element.price;
                        }else{
                            revenueAddresses[element.address] = element.price;
                        }
                    }

                    location.innerHTML = element.location;
                    csvText += `${element.location}, `;
                    address.innerHTML = element.address;
                    csvText += `${element.address}, `;
                    email.innerHTML = element.email;
                    csvText += `${element.email}, `;
                    if (element.phoneNumber) {
                        var rawNumber = element.phoneNumber.replace(/[^0-9]/g, '');
                        rawNumber = parseInt(rawNumber);
                        phoneNumber.innerHTML = rawNumber;
                        csvText += `${rawNumber}, `;
                    }else{
                        csvText += `, `;
                    }
                    quantity.innerHTML = element.quantity;
                    csvText += `${element.quantity}, `;
                    bagSum += element.quantity;
                    price.innerHTML = "$" + element.price.toFixed(2);
                    csvText += `${element.price.toFixed(2)}, `;
                    totalSum += element.price;
                    paymentType.innerHTML = element.paymentType;
                    csvText += `${element.paymentType}, `;
                    if (element.orderStatus === "Payment Pending"){
                        orderStatus.innerHTML = "<i>Payment Pending</i>";
                    } else {
                        orderStatus.innerHTML = element.orderStatus;
                    }
                    csvText += `${element.orderStatus}, `;
                    if (!element.adminUpdates || element.adminUpdates === "undefined"){
                        adminUpdates.innerHTML = "";
                        csvText += `, `;
                    } else {
                        adminUpdates.innerHTML = element.adminUpdates;
                        csvText += `${element.adminUpdates}, `;
                    }
                    csvText += '\r\n';
                    

                    if (element.orderStatus === "Pending" || element.orderStatus === "Payment Pending" || element.orderStatus === "Pick Up Pending"){

                        if ((localStorage.getItem('type') && localStorage.getItem('type') === 'superadmin') || (locationManagers.includes(element.address) && localStorage.getItem("adminUsername") && locationManagers[locationManagers.indexOf(element.address)+1].includes(localStorage.getItem("adminUsername")))){
                            orderStatus.innerHTML += "<br>";
                            
                            const acceptButton = createButton(true, false);
                            const cancelButton = createButton(false, false);

                            const reversionButtonForSearch = createButton(false, true);
                            reversionButtonForSearch.disabled = true;
                            reversionButtonForSearch.style.visibility = 'hidden';

                            const reversionButtonForMain = createButton(false, true);
                            reversionButtonForMain.disabled = true;
                            reversionButtonForMain.style.visibility = 'hidden';

                            // pretty bad spaghetti code
                            var acceptButton2ForMain = null;
                            var cancelButton2ForMain = null;
                            var acceptButton2ForSearch = null;
                            var cancelButton2ForSearch = null;
                            if (element.orderStatus === "Payment Pending"){
                                acceptButton2ForMain = createButton(true, false); // new buttons for pickup pending
                                acceptButton2ForMain.disabled = true;
                                acceptButton2ForMain.style.visibility = 'hidden';
                                cancelButton2ForMain = createButton(false, false);
                                cancelButton2ForMain.disabled = true;
                                cancelButton2ForMain.style.visibility = 'hidden';

                                acceptButton2ForSearch = createButton(true, false); // new buttons for pickup pending
                                acceptButton2ForSearch.disabled = true;
                                acceptButton2ForSearch.style.visibility = 'hidden';
                                cancelButton2ForSearch = createButton(false, false);
                                cancelButton2ForSearch.disabled = true;
                                cancelButton2ForSearch.style.visibility = 'hidden';
                                
                                acceptButton2ForSearch.onclick = function(){
                                    var ogAdminUpdatesCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex+1];
                                    updateOrderStatus("Accepted", element._id.toString(), element.address, element.quantity, acceptButton, cancelButton, orderStatus, false, "Pick Up Pending", adminUpdates, reversionButtonForSearch, acceptButton2ForSearch, cancelButton2ForSearch, ogAdminUpdatesCell)
                                    // update order status cell of the non-searched pending order
                                    if (searchUsed() && document.getElementById(element._id.toString())){
                                        var ogCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex];
                                        var ogButton1 = ogCell.children[0];
                                        var ogButton2 = ogCell.children[1];
                                        
                                        updateOrderStatus("Accepted", element._id.toString(), element.address, element.quantity, ogButton1, ogButton2, ogCell, true, "Pick Up Pending", ogAdminUpdatesCell, reversionButtonForMain, acceptButton2ForMain, cancelButton2ForMain)
                                    }
                                }
        
                                cancelButton2ForSearch.onclick = function(){
                                    var ogAdminUpdatesCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex+1];
                                    updateOrderStatus("Cancelled", element._id.toString(), element.address, element.quantity, acceptButton, cancelButton, orderStatus, false, "Pick Up Pending", adminUpdates, null, acceptButton2ForSearch, cancelButton2ForSearch, ogAdminUpdatesCell)
                                    // update order status cell of the non-searched pending order
                                    if (searchUsed() && document.getElementById(element._id.toString())){
                                        var ogCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex];
                                        var ogButton1 = ogCell.children[0];
                                        var ogButton2 = ogCell.children[1];
                                        
                                        updateOrderStatus("Cancelled", element._id.toString(), element.address, element.quantity, ogButton1, ogButton2, ogCell, true, "Pick Up Pending", ogAdminUpdatesCell, null, acceptButton2ForMain, cancelButton2ForMain)
                                    }
                                }

                                acceptButton2ForMain.onclick = function(){
                                    var ogCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex];
                                    var ogAdminUpdatesCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex+1];
                                    updateOrderStatus("Accepted", element._id.toString(), element.address, element.quantity, acceptButton, cancelButton, ogCell, false, "Pick Up Pending", ogAdminUpdatesCell, reversionButtonForMain, acceptButton2ForMain, cancelButton2ForMain)
                                }
        
                                cancelButton2ForMain.onclick = function(){
                                    var ogCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex];
                                    var ogAdminUpdatesCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex+1];
                                    updateOrderStatus("Cancelled", element._id.toString(), element.address, element.quantity, acceptButton, cancelButton, ogCell, false, "Pick Up Pending", ogAdminUpdatesCell, null, acceptButton2ForMain, cancelButton2ForMain)
                                }
                            }

                            //orderStatus.innerHTML = element.orderStatus + "  ";

                            acceptButton.onclick = function(){
                                var ogAdminUpdatesCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex+1];
                                updateOrderStatus("Accepted", element._id.toString(), element.address, element.quantity, acceptButton, cancelButton, orderStatus, false, element.orderStatus, adminUpdates, reversionButtonForSearch, acceptButton2ForSearch, cancelButton2ForSearch, ogAdminUpdatesCell)
                                // update order status cell of the non-searched pending order
                                if (searchUsed() && document.getElementById(element._id.toString())){
                                    var ogCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex];
                                    var ogButton1 = ogCell.children[0];
                                    var ogButton2 = ogCell.children[1];
                                    
                                    updateOrderStatus("Accepted", element._id.toString(), element.address, element.quantity, ogButton1, ogButton2, ogCell, true, element.orderStatus, ogAdminUpdatesCell, reversionButtonForMain, acceptButton2ForMain, cancelButton2ForMain)
                                }
                            }

                            cancelButton.onclick = function(){
                                var ogAdminUpdatesCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex+1];
                                updateOrderStatus("Cancelled", element._id.toString(), element.address, element.quantity, acceptButton, cancelButton, orderStatus, false, element.orderStatus, adminUpdates, null, acceptButton2ForMain, cancelButton2ForMain, ogAdminUpdatesCell)
                                // update order status cell of the non-searched pending order
                                if (searchUsed() && document.getElementById(element._id.toString())){
                                    var ogCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex];
                                    var ogButton1 = ogCell.children[0];
                                    var ogButton2 = ogCell.children[1];
                                    updateOrderStatus("Cancelled", element._id.toString(), element.address, element.quantity, ogButton1, ogButton2, ogCell, true, element.orderStatus, ogAdminUpdatesCell, null, acceptButton2ForSearch, cancelButton2ForSearch)
                                }
                            }

                            

                            reversionButtonForSearch.onclick = function(){
                                var ogAdminUpdatesCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex+1];
                                updateOrderStatus("Cancelled", element._id.toString(), element.address, element.quantity, null, reversionButtonForSearch, orderStatus, false, "Completed", adminUpdates, null, acceptButton2ForMain, cancelButton2ForMain, ogAdminUpdatesCell)
                                // update order status cell of the non-searched pending order
                                if (searchUsed() && document.getElementById(element._id.toString())){
                                    var ogCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex];
                                    updateOrderStatus("Cancelled", element._id.toString(), element.address, element.quantity, null, reversionButtonForMain, ogCell, true, "Completed", ogAdminUpdatesCell, null, acceptButton2ForSearch, cancelButton2ForSearch)
                                }
                            }

                            reversionButtonForMain.onclick = function(){
                                var ogCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex];
                                var ogAdminUpdatesCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex+1];
                                updateOrderStatus("Cancelled", element._id.toString(), element.address, element.quantity, null, reversionButtonForMain, ogCell, false, "Completed", ogAdminUpdatesCell, null, acceptButton2ForMain, cancelButton2ForMain)
                            }

                            orderStatus.appendChild(acceptButton);
                            orderStatus.appendChild(document.createTextNode(" "));
                            orderStatus.appendChild(cancelButton);
                        } else {
                            if ((element.orderStatus === 'Accepted' || element.orderStatus === 'Completed') && localStorage.getItem('type') && localStorage.getItem('type') === 'superadmin'){
                                const cancelButton = createButton(false, true);
                                
                                cancelButton.onclick = function(){
                                    if (document.getElementById(element._id.toString())){ // if original cell is loaded in closed orders then update it too
                                        var ogAdminUpdatesCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex+1];
                                        updateOrderStatus("Cancelled", element._id.toString(), element.address, element.quantity, null, cancelButton, orderStatus, false, element.orderStatus, adminUpdates, null, null, null, ogAdminUpdatesCell)
                                    } else {
                                        updateOrderStatus("Cancelled", element._id.toString(), element.address, element.quantity, null, cancelButton, orderStatus, false, element.orderStatus, adminUpdates, null, null, null, null)
                                    }
                                    // update order status cell of the non-searched pending order
                                    if (searchUsed() && document.getElementById(element._id.toString())){
                                        var ogCell = document.getElementById(element._id.toString()).getElementsByTagName("td")[orderStatusCellIndex];
                                        var ogButton2 = ogCell.children[0];
                                        updateOrderStatus("Cancelled", element._id.toString(), element.address, element.quantity, null, ogButton2, ogCell, true, element.orderStatus, ogAdminUpdatesCell)
                                    }
                                }

                                orderStatus.appendChild(document.createTextNode(" "));
                                orderStatus.appendChild(cancelButton);
                            }
                        }

                    }

                    tr.appendChild(idNumber);
                    tr.appendChild(date);
                    tr.appendChild(location);
                    tr.appendChild(address);
                    tr.appendChild(email);
                    tr.appendChild(phoneNumber);
                    tr.appendChild(quantity);
                    tr.appendChild(price);
                    tr.appendChild(paymentType);
                    tr.appendChild(orderStatus);
                    if (localStorage.getItem('type') && localStorage.getItem('type') === 'superadmin'){
                        tr.appendChild(adminUpdates);
                    }
                    table.appendChild(tr);

                    if (isFirstRequest){
                        pendingIDs.push(element._id.toString());
                    }
                    if (!isFirstRequest && !searchUsed()){ // if not an existing order
                        existingOrderIDs.push(element._id.toString());
                    }
                }
            });

            if (isFirstRequest){
                document.getElementById("pendingLabel").innerHTML = "Pending Orders (" + Object.keys(data).length + ")";
            }

            var showVisitsData = false;
            if (onlyDateRangeUsed()){
                // getHits
                showVisitsData = true;
                const response = await fetch(`${API_URL}/getHits`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        //'Authorization': localStorage.getItem('token')
                        //"Access-Control-Allow-Origin": "http://localhost:3000",
                        //"Access-Control-Allow-Credentials": "true",
                    },
                    body: JSON.stringify({
                        'startDate': startDate,
                        'endDate': endDate
                    })
                    //credentials: 'include'
                }).catch((error) => {
                    return console.log(error)
                })
                
                const hitData = await response.json();
                hitData.forEach(element => {
                    const hitDate = new Date(element.time);
                    const timeHit = hitDate.toLocaleTimeString("us-PT");
                    const hourHit = timeHit.substring(0, timeHit.indexOf(":")) + timeHit.slice(-2).toLowerCase();
                    if (hourHit in hitHours){
                        hitHours[hourHit] = hitHours[hourHit] + 1; // increment hour count
                    }
                    const dayHit = hitDate.toString().substring(0,3);
                    if (dayHit in hitDays){
                        hitDays[dayHit] = hitDays[dayHit] + 1; // increment day count
                    }
                    const monthHit = hitDate.toString().substring(4,7);
                    if (monthHit in hitMonths){
                        hitMonths[monthHit] = hitMonths[monthHit] + 1; // increment month count
                    }

                });

                document.getElementById("hitsVsOrders").innerHTML = "<u>Website traffic:</u>&nbsp  Visits: " + hitData.length + "&nbsp  Orders: " + Object.keys(data).length + "&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" + ((Object.keys(data).length / hitData.length)*100).toFixed(1) + "% of site visitors placed orders";
            } else {
                document.getElementById("hitsVsOrders").innerHTML = "<i>For site visits data, search using only date range</i>";
            }

            if (searchUsed()){
                document.getElementById("csvButton").removeAttribute("hidden");

                const numOrders = Object.keys(data).length;
                const totalRev = totalSum.toFixed(2);
                const searchParamString = getSearchParamString();

                document.getElementById("searchLabel").innerHTML = "Searched Orders (" + numOrders + ")";
                document.getElementById("searchTotalHeader").innerHTML = "Total ($" + totalRev + ")";
                document.getElementById("searchBagHeader").innerHTML = "Bags (" + bagSum + ")";

                document.getElementById("searchParamsLabel").innerHTML = "Your search: " + searchParamString;
                document.getElementById("orderCountLabel").innerHTML = "Number of orders: " + numOrders;
                document.getElementById("bagCountLabel").innerHTML = "Number of bags: " + bagSum;
                document.getElementById("totalPriceLabel").innerHTML = "Total revenue: $" + totalRev;

                var csvTop = `Stats for: ${searchParamString.replaceAll(",", ";")}\r\n`;
                csvTop += "Number of orders: " + numOrders + "\r\n";
                csvTop += "Number of bags: " + bagSum + "\r\n";
                csvTop += "Total revenue: $" + totalRev + "\r\n\r\n";

                csvTop += csvText;
                csvText = csvTop;

                fillPlot(Object.keys(orderHours), Object.values(orderHours), Object.keys(hitHours), Object.values(hitHours), 'hourPlot', 650, !showVisitsData, 'Orders', showVisitsData == true ? 'Orders & Visits by Hour' : 'Orders by Hour');
                fillPlot(Object.keys(orderDays), Object.values(orderDays), Object.keys(hitDays), Object.values(hitDays), 'dayPlot', 500, !showVisitsData, 'Orders', showVisitsData == true ? 'Orders & Visits by Day' : 'Orders by Day');
                fillPlot(Object.keys(orderMonths), Object.values(orderMonths), Object.keys(hitMonths), Object.values(hitMonths), 'monthPlot', 650, !showVisitsData, 'Orders', showVisitsData == true ? 'Orders & Visits by Month' : 'Orders by Month');
                fillPlot(Object.keys(revenueAddresses), Object.values(revenueAddresses), null, null, 'revenueAddressPlot', 500, true, 'Revenue', 'Total Revenue by Address');

                function fillPlot(x1, y1, x2, y2, divId, w, oneTrace, barLabel, graphTitle){
                    var trace1 = {
                        x: x1,
                        y: y1,
                        name: barLabel,
                        type: 'bar'
                    };
                    var barData
                    if (!oneTrace){
                        var trace2 = {
                            x: x2,
                            y: y2,
                            name: 'Visits',
                            type: 'bar'
                        };
                        barData = [trace1, trace2];
                    }else{
                        barData = [trace1];
                    }
                    var layout = {
                        barmode: 'stack',
                        autosize: false,
                        width: w,
                        height: 400,
                        margin: {
                            l: 50,
                            r: 50,
                            b: 100,
                            t: 100,
                            pad: 4
                        },
                        plot_bgcolor: '#fcfcfc',
                        showlegend: true,
                        title: {
                            text: graphTitle,
                            font: {
                              family: 'Courier New, monospace',
                              size: 18
                            },
                            xref: 'paper',
                            title_y: 0,
                            automargin: true
                          },
                    };
                    Plotly.newPlot(divId, barData, layout);
                }
            } else {
                
            }
            

            if (numOrdersAlreadyLoaded === data.length){
                unstuckOffset += 1;
                loadMoreButton.innerHTML = "<span style='color: red;'>Try Again</span>";
            } else {
                unstuckOffset = 0;
                loadMoreButton.innerHTML = "<span style='color: black;'>Load More</span>";
            }
            loadMoreButton.disabled = false;

            function searchUsed(){
                if (
                    searchValue !== "" || 
                    startDate !== 1672590568000 || 
                    locationSearch !== "All" || 
                    addressSearch !== "All" || 
                    paymentSearch !== "All" || 
                    statusSearch !== "All" || 
                    adminSearch !== "All" || 
                    minPriceSearch !== -1 || 
                    maxPriceSearch !== -1 || 
                    idSearch !== -1
                ){
                    return true;
                }else{
                    return false;
                }
            }

            function getSearchParamString(){
                var text = "(";
                if ( startDate !== 1672590568000 )  { text += document.getElementById("daterangetext").value + ", "};
                if ( searchValue !== "" )           { text += searchValue + ", "};
                if ( locationSearch !== "All" )     { text += locationSearch + ", "};
                if ( addressSearch !== "All" )      { text += addressSearch + ", "};
                if ( paymentSearch !== "All" )      { text += paymentSearch + ", "};
                if ( statusSearch !== "All" )       { text += statusSearch + ", "};
                if ( adminSearch !== "All" )        { text += "Updated by admin: " + adminSearch + ", "};
                if ( minPriceSearch !== -1 )        { text += "Min $" + minPriceSearch + ", "};
                if ( maxPriceSearch !== -1 )        { text += "Max $" + maxPriceSearch + ", "};
                if ( idSearch !== -1 )              { text += "ID #" + idSearch + ", "};
                return text.replace(/,\s*$/, "") + ")";
            }

            function onlyDateRangeUsed(){
                if (
                    searchValue === "" && 
                    startDate !== 1672590568000 &&  
                    locationSearch === "All" &&  
                    addressSearch === "All" &&  
                    paymentSearch === "All" &&  
                    statusSearch === "All" &&  
                    adminSearch === "All" &&  
                    minPriceSearch === -1 &&  
                    maxPriceSearch === -1 &&  
                    idSearch === -1
                ){
                    return true;
                }else{
                    return false;
                }
            }
        }

        async function updateOrderStatus(buttonStatus, orderID, address, numBags, button1, button2, cell, isRepeatFromSearch, origStatus, adminUpdatesCell, reversionButton, button3, button4, ogAdminCell){
            var isReversion = false;
            var orderStatus = "Pending";

            if (origStatus === "Pick Up Pending"){
                if (buttonStatus === "Accepted"){
                    cell.innerHTML = "Completed";
                    orderStatus = "Completed";
                } else {
                    cell.innerHTML = "Cancelled";
                    orderStatus = "Cancelled";
                }
                buttonChange(false);
                decrementLabel();

            }else if (origStatus === "Payment Pending"){
                if (buttonStatus === "Accepted"){
                    cell.innerHTML = "Pick Up Pending<br>";
                    button3.disabled = false;
                    button3.style.visibility = 'visible';
                    button4.disabled = false;
                    button4.style.visibility = 'visible';
                    cell.appendChild(button3);
                    cell.appendChild(document.createTextNode(" "));
                    cell.appendChild(button4);
                    orderStatus = "Pick Up Pending";
                    buttonChange(true);
                } else {
                    cell.innerHTML = "Cancelled";
                    orderStatus = "Cancelled";
                    buttonChange(false);
                    decrementLabel();
                }
                

            }else if (origStatus === "Pending"){ // check Pending for legacy orders, will remove
                if (buttonStatus === "Accepted"){
                    cell.innerHTML = "Completed";
                    orderStatus = "Completed";
                } else {
                    cell.innerHTML = "Cancelled";
                    orderStatus = "Cancelled";
                }
                buttonChange(false);

            }else if(origStatus === "Completed" || origStatus === "Accepted"){
                cell.innerHTML = "Cancelled";
                orderStatus = "Cancelled";
                buttonChange(false);
            }

            if (!isRepeatFromSearch){
                const response = await fetch(`${API_URL}/updateOrderStatus`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': localStorage.getItem('token')
                    },
                    body: JSON.stringify({
                        'id': orderID,
                        'orderStatus': orderStatus,
                        'isReversion': isReversion,
                        'adminUpdates': adminUpdatesCell.innerHTML,
                        'origStatus': origStatus,
                        'buttonStatus': buttonStatus,
                        'address': address,
                        'numBags': numBags
                    })
                });

                if (!response || !response.ok){
                    console.log('Bad response / no response');
                    return navigate("../admin");
                }
                console.log(response);
                const data = await response.json();
                console.log(data.order.adminUpdates, 981014);
                adminUpdatesCell.innerHTML = data.order.adminUpdates;
                if (ogAdminCell){
                    ogAdminCell.innerHTML = data.order.adminUpdates;
                }
                if (document.getElementById(data.inventory.address) && document.getElementById(data.inventory.address + "QtyCell")){
                    var qtyInput = document.getElementById(data.inventory.address);
                    var qtyCell = document.getElementById(data.inventory.address + "QtyCell");
                    var ogQty = qtyInput.value;
                    qtyInput.value = data.inventory.quantity;
                    var invAutoUpdateTextNode = document.createTextNode("ID#" + data.order.idNumber + " updated (" + ogQty + " to " + data.inventory.quantity + ")");
                    var div = document.createElement("div");
                    div.style.fontSize = "14px";
                    div.appendChild(invAutoUpdateTextNode);
                    qtyCell.insertBefore(div, qtyCell.firstChild);
                }
                if (data.inventory.quantity <= 5){
                    sendLowStockEmail(data.inventory.address, data.inventory.quantity, data.inventory.locationManagers);
                    sendLowStockText(data.inventory.address, data.inventory.quantity, data.inventory.locationManagers);
                }
            }

            function decrementLabel(){
                if (!isRepeatFromSearch){
                    const _pendingLabel = document.getElementById("pendingLabel");
                    var numb = _pendingLabel.innerHTML.replace(/[^0-9]/g, '');
                    numb = parseInt(numb)-1;
                    _pendingLabel.innerHTML = "Pending Orders (" + numb + ")";
                }
            }

            function buttonChange(isPaymentToPickup){
                button2.disabled = true; // actual reversion button
                button2.style.visibility = 'hidden';
        
                if (button1){
                    button1.disabled = true;
                    button1.style.visibility = 'hidden';
                } else {
                    isReversion = true
                }
                
                if (reversionButton && !isPaymentToPickup && localStorage.getItem('type') && localStorage.getItem('type') === 'superadmin'){
                    reversionButton.disabled = false;
                    reversionButton.style.visibility = 'visible';
                    cell.appendChild(document.createTextNode(" "));
                    cell.appendChild(reversionButton);
                }
            }
        }


        function createButton(makeAcceptButton, isReversionButton){
            if (makeAcceptButton){
                const acceptButton = document.createElement('button');
                acceptButton.style.border = "none";
                acceptButton.style.background = "#63e600";
                acceptButton.onmouseover = function(){
                    acceptButton.style.background = "#58cc00";
                }
                acceptButton.onmouseout = function(){
                    acceptButton.style.background = "#63e600";
                }
                const checkmark = document.createElement('img');
                checkmark.src = "checkmark2.png";
                checkmark.width = 24;
                checkmark.height = 24;
                acceptButton.appendChild(checkmark);

                return acceptButton;
            } else {
                const cancelButton = document.createElement('button');
                cancelButton.style.border = "none";
                cancelButton.style.background = "#e6001b";
                cancelButton.onmouseover = function(){
                    cancelButton.style.background = "#cc0018";
                }
                cancelButton.onmouseout = function(){
                    cancelButton.style.background = "#e6001b";
                }
                const x = document.createElement('img');
                x.src = "x.png";
                if (isReversionButton){
                    x.width = 20;
                    x.height = 20;
                    x.style.position = "relative";
                    x.style.top = "-2px"
                    x.style.left = "-1px"
                    cancelButton.style.width = "30px";
                    cancelButton.style.height = "25px";
                } else {
                    x.width = 24;
                    x.height = 24;
                }
                cancelButton.appendChild(x);

                return cancelButton;
            }
        }

        function parseDate(dateLongFormat) {
            return dateLongFormat.substring(8,10) + '-' + dateLongFormat.substring(4,7) + '-' + dateLongFormat.substring(11,15);
        }

        const loadMoreContainer = document.getElementById('LoadMoreButtonContainer');
        loadMoreContainer.appendChild(loadMoreButton)

        const searchForm = document.getElementById("searchForm");
        searchForm.addEventListener("submit", function(event){
            event.preventDefault();
            getData(false, true); // get search orders
        });

        const searchInput = document.getElementById("orderSearchInput");
        searchInput.style.width = "350px";

        const locationDropdown = document.createElement("select");
        locationDropdown.style.position = "relative";
        locationDropdown.style.top = "2px";
        locationDropdown.style.left = "30px";
        const addressDropdown = document.createElement("select");
        addressDropdown.style.position = "relative";
        addressDropdown.style.top = "2px";
        addressDropdown.style.left = "37px";
        const paymentDropdown = document.createElement("select");
        paymentDropdown.style.position = "relative";
        paymentDropdown.style.top = "2px";
        paymentDropdown.style.left = "43px";
        const statusDropdown = document.createElement("select");
        statusDropdown.style.position = "relative";
        statusDropdown.style.top = "2px";
        statusDropdown.style.left = "50px";
        const adminDropdown = document.createElement("select");
        adminDropdown.setAttribute("id", "adminSearchDropdown");
        adminDropdown.style.position = "relative";
        adminDropdown.style.top = "2px";
        adminDropdown.style.left = "57px";
        const minPriceField = document.createElement("input");
        minPriceField.type = "number";
        minPriceField.min = 0;
        minPriceField.step = "any";
        minPriceField.placeholder = "min $";
        minPriceField.style.height = "24px";
        minPriceField.style.width = "65px";
        minPriceField.style.position = "relative";
        minPriceField.style.top = "2px";
        minPriceField.style.left = "70px";
        const maxPriceField = document.createElement("input");
        maxPriceField.type = "number";
        maxPriceField.min = 0;
        maxPriceField.step = "any";
        maxPriceField.placeholder = "max $";
        maxPriceField.style.height = "24px";
        maxPriceField.style.width = "65px";
        maxPriceField.style.position = "relative";
        maxPriceField.style.top = "2px";
        maxPriceField.style.left = "75px";
        const idField = document.createElement("input");
        idField.type = "number";
        idField.min = 0;
        idField.step = 1;
        idField.placeholder = "ID #";
        idField.style.height = "24px";
        idField.style.width = "65px";
        idField.style.position = "relative";
        idField.style.top = "2px";
        idField.style.left = "83px";

        addChangeEventToPriceSearch(minPriceField);
        addChangeEventToPriceSearch(maxPriceField);

        function addChangeEventToPriceSearch(field){
            field.addEventListener("change", function(event){
                event.preventDefault();

                if (minPriceField.value.length !== 0){
                    minPriceSearch = minPriceField.value;
                }else{
                    minPriceSearch = -1;
                }

                if (maxPriceField.value.length !== 0){
                    maxPriceSearch = maxPriceField.value;
                }else{
                    maxPriceSearch = -1;
                }
            });
        }

        idField.addEventListener("change", function(event){
            event.preventDefault();
            if (idField.value.length !== 0){
                idSearch = idField.value;
            }else{
                idSearch = -1;
            }
            
        });

        searchForm.appendChild(locationDropdown);
        searchForm.appendChild(addressDropdown);
        searchForm.appendChild(paymentDropdown);
        searchForm.appendChild(statusDropdown);
        

        async function getDropdownValues(){
            const response = await fetch(`${API_URL}/getDropdownValues`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    //'Authorization': localStorage.getItem('token')
                }
            });

            if (!response || !response.ok){
                return console.log('Bad response / no response')
            }
            console.log(response);
            const data = await response.json();
            console.log(data);

            const paymentTypes = [
                "Cash",
                "e-Transfer",
                "Square"
            ]

            const statuses = [
                "Payment Pending",
                "Pick Up Pending",
                "Completed",
                "Cancelled"
            ]
            
            fillDropdown(locationDropdown, data.locations, "Any Location", "locations");
            fillDropdown(addressDropdown, data.addresses, "Any Address", "addresses");
            fillDropdown(paymentDropdown, paymentTypes, "Any Payment", "payments");
            fillDropdown(statusDropdown, statuses, "Any Status", "statuses");

            if (localStorage.getItem('type') && localStorage.getItem('type') === 'superadmin'){
                searchForm.appendChild(adminDropdown);
                fillDropdown(adminDropdown, data.admins, "Any Admin", "admins");
            }

            function fillDropdown(dropdown, dataProperty, defaultVal, searchVar){
                if (dataProperty){
                    const dropOption = document.createElement('option');
                    dropOption.value = 'All';
                    dropOption.innerHTML = defaultVal;
                    dropdown.appendChild(dropOption);
                    dataProperty.forEach(element => {
                        if (element !== ""){
                            const dropOption = document.createElement('option');
                            dropOption.value = element;
                            dropOption.innerHTML = element;
                            dropdown.appendChild(dropOption);
                        }
                    });

                    dropdown.addEventListener('change', function(event){
                        event.preventDefault();
                        if (searchVar === "locations"){
                            locationSearch = dropdown.value;
                        }
                        if (searchVar === "addresses"){
                            addressSearch = dropdown.value;
                        }
                        if (searchVar === "payments"){
                            paymentSearch = dropdown.value;
                        }
                        if (searchVar === "statuses"){
                            statusSearch = dropdown.value;
                        }
                        if (searchVar === "admins"){
                            adminSearch = dropdown.value;
                        }
                        searchForm.requestSubmit();
                    });
                }
            }

            searchForm.appendChild(minPriceField);
            searchForm.appendChild(maxPriceField);
            searchForm.appendChild(idField);
            searchForm.appendChild(document.createElement("br"));

            const searchButton = document.getElementById("searchButton");
            searchButton.style.position = "relative";
            searchButton.style.top = "5px";
            searchForm.appendChild(searchButton);

            const clearButton = document.getElementById("clearButton");
            clearButton.style.position = "relative";
            clearButton.style.top = "5px";
            clearButton.style.left = "5px";
            clearButton.addEventListener("click", function(event){
                event.preventDefault();
                searchInput.value = "";
                searchValue = "";
                startDate = 1672590568000;
                endDate = Date.now();
                locationDropdown.value = "All";
                locationSearch = "All";
                addressDropdown.value = "All";
                addressSearch = "All";
                paymentDropdown.value = "All";
                paymentSearch = "All";
                statusDropdown.value = "All";
                statusSearch = "All";
                adminDropdown.value = "All";
                adminSearch = "All";
                minPriceField.value = "";
                minPriceSearch = -1;
                maxPriceField.value = "";
                maxPriceSearch = -1;
                idField.value = "";
                idSearch = -1;
                document.getElementById("csvButton").setAttribute("hidden", "hidden");
                getData(false, false, true);
            });
            searchForm.appendChild(clearButton);

            const csvButton = document.getElementById("csvButton");
            csvButton.style.position = "relative";
            csvButton.style.top = "5px";
            csvButton.style.left = "10px";
            
            function exportCSV(filename, contentType) {
                // Create a blob
                var blob = new Blob([csvText], { type: contentType });
                var url = URL.createObjectURL(blob);
                
                // Create a link to download it
                var pom = document.createElement('a');
                pom.href = url;
                pom.setAttribute('download', filename);
                pom.click();
            }

            csvButton.addEventListener("click", function(event){
                event.preventDefault();
                const dateNow = new Date(Date.now());
                const parsedDate = parseDate(dateNow.toString());
                exportCSV('OrderSummary_' + parsedDate + '.csv', 'text/csv;charset=utf-8;');
            });
            searchForm.appendChild(csvButton);
            
        }
        getDropdownValues();


        // Duplicate function in Inventory
        async function sendLowStockEmail(address, quantity, locationManagerString) {
            try {
            const response = await fetch(`${API_URL}/send_mail`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    type: "admin",
                    body: "Inventory at " + address + " is running low. There are " + quantity + " bags remaining.",
                    locationManagers: locationManagerString
                })
            }).catch((error) => {
                return console.log(error);
            });
    
            if (!response || !response.ok) {
                return console.log("Bad response / no response");
            }
    
            const data = await response.json();
            console.log(data);
            } catch (error) {
            console.log(error);
            }
        };

        // Duplicate function in Inventory
        async function sendLowStockText(address, quantity, locationManagerString) {
            try {
            const response = await fetch(`${API_URL}/messages`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token'),
                },
                body: JSON.stringify({
                    type: "admin",
                    body: "Inventory at " + address + " is running low. There are " + quantity + " bags remaining.",
                    locationManagers: locationManagerString
                })
            }).catch((error) => {
                return console.log(error)
            })
    
            if (!response || !response.ok){
                return console.log('Bad response / no response')
            }
            const data = await response.json();
            console.log(data, "TWILIO TEST 2");
            } catch (error) {
            console.log(error);
            }
        }


        getData(true, false);  // get pending orders
        getData(false, false);  // get completed orders

    }, []);

    function handleDateRangeApply(event, picker){
        event.preventDefault();
        startDate = picker.startDate.unix()*1000; // unix is in seconds vs Date.now() which is in ms, so need to 1000x
        endDate = picker.endDate.unix()*1000;
        const searchForm = document.getElementById("searchForm");
        searchForm.requestSubmit();
    }

    return(
    <div>
      <Card id="orderSummaryCard">
      <Card.Header> Order Summary</Card.Header>
      <Card.Body>
      <Table bordered fluid>
          <thead>
            <tr>
            <th>
                <form id="searchForm">
                    <input class="orderSearchInput" id="orderSearchInput" type="text" placeholder="Search... (email, phone number)"></input>
                    
                    <DateRangePicker
                    initialSettings={{ startDate: '1/1/2023', endDate: {
                        function(){
                            return new Date(Date.now()).toLocaleString().split(',')[0]}} } // end date = current date
                        } 
                    onApply = {handleDateRangeApply}>
                        <input type="text" class="form-control"  id='daterangetext' value="Use Calendar"></input>
                    </DateRangePicker>
                    
                </form>
                <Button id="searchButton" variant="primary" type="submit">Search</Button>
                <Button id="clearButton" variant="secondary">Clear</Button>
                <Button id="csvButton" variant="success" hidden>Export CSV</Button>
            </th>
            </tr>
          </thead>
        </Table>
        {/* stats panel */}
        <Table id="statSummary" bordered fluid> 
          <thead>
            <tr>
            <div>
            <p id="statHelpLabel"><i>Use search for statistics</i></p>
            <p id="searchParamsLabel"></p>
            <Button id="statsToggleButton" variant="outline-primary">Show Stats</Button>
            </div>
            <tb id="stats" hidden>
                <p id="orderCountLabel"></p>
                <p id="bagCountLabel"></p>
                <p id="totalPriceLabel"></p>
                <p id="hitsVsOrders"></p>
                <div class="plots">
                    <div id="hourPlot"></div>
                    <div id="dayPlot"></div>
                </div>
                <div class="plots">
                    <div id="monthPlot"></div>
                    <div id="revenueAddressPlot"></div>
                </div>
            </tb>
            </tr>
          </thead>
        </Table>
        <h5 id="searchLabel" hidden>Searched Orders:</h5>
        <Table id="searchSummary" hidden bordered fluid>
          <thead>
            <tr style={{backgroundColor:'#f6f6f6'}}>
              <th>ID</th>
              <th>Date</th>
              <th>Location</th>
              <th>Address</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th id="searchBagHeader">Bags</th>
              <th id="searchTotalHeader">Total</th>
              <th>Payment Type</th>
              <th>Order Status</th>
              <th id="searchAdminColumnHeader" hidden>Admin Updates</th>
            </tr>
          </thead>
          <tbody id="orderSummarySearchTable">
          </tbody>
        </Table>
        <h5 id="pendingLabel" hidden>Pending Orders:</h5>
        <Table id="pendingOrderSummary" bordered fluid>
          <thead>
            <tr style={{backgroundColor:'#f6f6f6'}}>
              <th>ID</th>
              <th>Date</th>
              <th>Location</th>
              <th>Address</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Bags</th>
              <th>Total</th>
              <th>Payment Type</th>
              <th>Order Status</th>
              <th id="pendingAdminColumnHeader" hidden>Admin Updates</th>
            </tr>
          </thead>
          <tbody id="orderSummaryPendingTable">
          </tbody>
        </Table>
        <h5 id="completedLabel" hidden>Closed Orders:</h5>
        <Table id="completedOrderSummary" bordered fluid>
          <thead>
            <tr style={{backgroundColor:'#f6f6f6'}}>
              <th>ID</th>
              <th>Date</th>
              <th>Location</th>
              <th>Address</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Bags</th>
              <th>Total</th>
              <th>Payment Type</th>
              <th>Order Status</th>
              <th id="completedAdminColumnHeader" hidden>Admin Updates</th>
            </tr>
          </thead>
          <tbody id="orderSummaryCompletedTable">
          </tbody>
        </Table>
        </Card.Body>
            <div id="LoadMoreButtonContainer" class="LoadMoreButtonContainer"></div>
        </Card>
    </div>
    )
};

export default OrderSummary;