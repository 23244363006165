import React from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { useEffect, useState } from "react";

const API_URL = process.env.REACT_APP_API;

const OrderPlaced = () => {
  useEffect(() => {
    if (localStorage.getItem("paymentType") == "e-Transfer") {
      document.getElementById("cardText").innerHTML =
        '<b>Please wait to be contacted by our representative to confirm that your order is ready <u>before</u> going to the pickup location.</b>' +
        "<br><br>e-Transfer payment to rotaryogopogo5060@gmail.com and enter <b>" + localStorage.getItem("orderNumber") + "</b> in the 'Message to Contact' section." + 
        '<br><br><div style="text-align:center">Order number: ' + localStorage.getItem("orderNumber") + '<br>Pickup location: ' + localStorage.getItem("address") + '<br><br>Total: $' + localStorage.getItem("total") + "</div>"
    } else if (localStorage.getItem("paymentType") == "Cash") {
      document.getElementById("cardText").innerHTML =
        "<b>Please wait to be contacted by our representative to confirm that your order is ready <u>before</u> going to the pickup location.</b>" +
        '<br><br><div style="text-align:center">Order number: ' + localStorage.getItem("orderNumber") + '<br>Pickup location: ' + localStorage.getItem("address") + '<br><br>Total: $' + localStorage.getItem("total") + "</div>"
    } else if (localStorage.getItem("paymentType") == "Square") {
      document.getElementById("cardText").innerHTML =
        "<b>Please wait to be contacted by our representative to confirm that your order is ready <u>before</u> going to the pickup location.</b>" +
        '<br><br><div style="text-align:center">Order number: ' + localStorage.getItem("orderNumber") + '<br>Pickup location: ' + localStorage.getItem("address") + '<br><br>Total: $' + localStorage.getItem("total") + "</div>"
    }
  }, []);

  return (
    <div class="d-flex justify-content-center" style={{ margin: "5em" }}>
      <div class="shadow-lg p-3 mb-5 bg-white rounded">
        <Card
          style={{
            width: "18rem",
          }}
        >
          
          <Card.Body>
          <Card.Img variant = "top" src="OrderPlacedFire.jpg" alt="Campfire Img" />
            <Card.Title style={{ justifyContent: "center", textAlign: "center"}}>
              <h5><b>Your Order Has Been Placed</b></h5>
            </Card.Title >
            <Card.Text>Thank you for supporting the Kelowna Ogopogo Rotary Club and Camp OAC!</Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroup.Item id="cardText">location</ListGroup.Item>
          </ListGroup>
        </Card>
      </div>
    </div>
  );
};

export default OrderPlaced;
