import { useState, useEffect } from "react";
import "./styles.css";
import {Form, Button} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Square from "../components/Square";
import { payments } from '@square/web-sdk';

const API_URL = process.env.REACT_APP_API;

const PaymentSelection = () => {

  const onOptionChange = e => {
    localStorage.setItem("paymentType",e.target.value);
  }

  useEffect(() => {
    const payMethods = ["e-Transfer", "Cash"];
    payMethods.forEach(function(method){
      assignEvent(method, "click");
    })

    if (localStorage.getItem("paymentType") && payMethods.includes(localStorage.getItem("paymentType"))){
      document.getElementById(localStorage.getItem("paymentType")+"-radio").checked = true;
    }

    function assignEvent(payMethod, eventAction){
      document.getElementById(payMethod).addEventListener(eventAction, function(event){
        event.preventDefault();
        payMethods.forEach(function(method){
          if (method !== payMethod){
            document.getElementById(method+"-radio").checked = false;
          }
        })
        document.getElementById(payMethod+"-radio").checked = true;
        localStorage.setItem("paymentType", payMethod);
      });
    }
  }, []);

  return (
    <div>
      <div
        class="container"
        id="payments"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <figure class="paymentOptionButton">
          <img id = "e-Transfer" src="etransfer.png" width="100" height="100" />
        </figure>
        &nbsp;&nbsp;
        <figure class="paymentOptionButton">
          <img id = "Square" src="square.png" width="100" height="100" />
        </figure>
        &nbsp;&nbsp;
        <figure class="paymentOptionButton">
          <img id = "Cash" src="cashIcon.png" width="100" height="100" />
        </figure>
        
    </div>
    <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
        }}
      >
      <form style = {{display: "flex",fontSize:'13.5px',fontWeight:'bold'}}>
          <input
            id = "e-Transfer-radio"
            name="payment"
            type= "radio"
            value="e-Transfer"
            onChange={onOptionChange}
          />
          <label for="e-Transfer-radio">&nbsp;e-Transfer</label>
          <input
            id = "Square-radio"
            name="payment"
            type="radio"
            disabled = "true"
            value="Square"
          />
          <label for="Square-radio">&nbsp;Credit/Debit</label>
          <input
            id = "Cash-radio"
            name = "payment"
            type= "radio"
            value="Cash"
            onChange={onOptionChange}
          />
          <label for="Cash-radio">&nbsp;Cash</label>
      </form>
    </div>
    </div>
  );
};

export default PaymentSelection;
