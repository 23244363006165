import React from "react";
import {Button, Card, Table} from 'react-bootstrap';
import {useEffect,useState } from "react";
import {useNavigate} from 'react-router-dom';
import './styles.css';

const API_URL = process.env.REACT_APP_API;

const Inventory = () => {

    const navigate = useNavigate();

    useEffect(() => {

        if (!localStorage.getItem('type') || localStorage.getItem('type') !== 'superadmin'){
            document.getElementById('addLocationCard').setAttribute("hidden", "hidden");
        }

        var existingAddresses = [];
        var existingLocations = [];
        var existingAdmins = [];

        async function getData() {
            if (localStorage.getItem('token') === null){
                return console.log('No Token')
            }

            const response = await fetch(`${API_URL}/getInventoryAndAdmins`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                }
              }).catch((error) => {
                return console.log(error)
              })

            if (!response || !response.ok){
                return console.log('Bad response / no response')
            }
            const data = await response.json();
            console.log(data);

            if (data.admins){
                data.admins.forEach(element => {
                    var adminUserTable = [];
    
                    adminUserTable.push(element.username);
                    if (element.email !== ""){
                        adminUserTable.push(element.email);
                    }
                    if (element.phone !== -1){
                        adminUserTable.push(element.phone);
                    }
    
                    if (element.status === "active"){
                        existingAdmins.push(adminUserTable);
                    }
                });
            }

            data.inventory.forEach(element => {
                insertRow(element)
            });
        }


        getData();

        const addLocationButton = document.getElementById('addLocationButton');
        
        async function addLocation(locationInput, addressInput) {
            if (localStorage.getItem('token') === null){
                return console.log('No Token')
            }

            addLocationButton.disabled = true;

            const response = await fetch(`${API_URL}/addLocation`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                    //"Access-Control-Allow-Origin": "http://localhost:3000",
                    //"Access-Control-Allow-Credentials": "true",
                },
                body: JSON.stringify({
                    'location': locationInput.value,
                    'address': addressInput.value
                })
            })

            if (!response || !response.ok){
                console.log('Bad response / no response');
                return navigate("../admin");
            }
            const data = await response.json();
            console.log(data);
            locationInput.value = "";
            addressInput.value = "";
            insertRow(data);
            addLocationButton.disabled = false;
        }

        function insertRow(element){
            const mainTable = document.getElementById('inventory');
            const tr = document.createElement('tr');
            const address = document.createElement('td');
            address.style.width = "325px";
            const quantity = document.createElement('td');
            quantity.setAttribute("id", element.address + "QtyCell");
            const adminsAtLocation = document.createElement('td');
            const status = document.createElement('td');
            address.innerHTML = element.address;
            if (!existingLocations.includes(element.location)){
                const thead = document.createElement('thead');
                thead.setAttribute('id', element.location + 'LabelHeader');
                const locationTR = document.createElement('tr');
                const locationLabel = document.createElement('th');
                locationLabel.style.backgroundColor = "#f6f6f6";
                locationLabel.innerHTML = element.location;
                const bagLabel = document.createElement('th');
                bagLabel.style.backgroundColor = "#f6f6f6";
                bagLabel.innerHTML = "Bags in Stock";//"<font size='-1'>Bags in Stock</font>";
                const adminLocLabel = document.createElement('th');
                adminLocLabel.style.backgroundColor = "#f6f6f6";
                adminLocLabel.innerHTML = "Location Managers";//"<font size='-1'>Bags in Stock</font>";
                const statusLabel = document.createElement('th');
                statusLabel.style.backgroundColor = "#f6f6f6";
                statusLabel.innerHTML = "Status";
                locationTR.appendChild(locationLabel);
                locationTR.appendChild(bagLabel);
                locationTR.appendChild(adminLocLabel);
                locationTR.appendChild(statusLabel);
                thead.appendChild(locationTR);
                mainTable.appendChild(thead);
                const newTbody = document.createElement('tbody');
                newTbody.setAttribute('id', element.location + 'Table');
                mainTable.appendChild(newTbody);
            }
            existingLocations.push(element.location); // allows duplicates
            existingAddresses.push(element.address);

            var locationManagers = element.locationManagers.split('~');
            if (locationManagers.length === 1 && locationManagers[0].length === 0){
                locationManagers.splice(0,1);
            }

            const inventoryInputForm = document.createElement('form');
            const inputField = document.createElement('input');
            inputField.setAttribute("id", element.address);
            const saveButton = document.createElement('button');

            if ((localStorage.getItem('type') && localStorage.getItem('type') === 'superadmin') || (localStorage.getItem('adminUsername') && locationManagers.includes(localStorage.getItem('adminUsername')))){
                inputField.style.width = "80px";
                inputField.type = "number";
                inputField.min = "0";
                inputField.defaultValue = element.quantity;
                saveButton.innerHTML = "Save";
                saveButton.disabled = true;
                inputField.oninput = function(){
                    if (inputField.value === ""){
                        saveButton.disabled = true;
                    } else {
                        saveButton.disabled = false;
                    }
                }
                inventoryInputForm.appendChild(inputField);
                inventoryInputForm.appendChild(document.createTextNode(" "));
                inventoryInputForm.appendChild(saveButton);
                inventoryInputForm.appendChild(document.createTextNode(" "));
                inventoryInputForm.addEventListener("submit", function(event){
                    event.preventDefault();
                    updateInventory(false);
                });
            }else{
                inputField.style.width = "80px";
                inputField.defaultValue = element.quantity;
                inputField.disabled = true;
                inventoryInputForm.appendChild(inputField);
            }

            const statusSelect = document.createElement("select");

            const adminSelect = document.createElement("select");
            adminSelect.setAttribute("class", "locationManagerDropdown");
            
            async function updateInventory(isActiveUpdate, isLocManagerUpdate, locManagerString){
                saveButton.disabled = true;
                const response = await fetch(`${API_URL}/updateInventory`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': localStorage.getItem('token')
                    },
                    body: JSON.stringify({
                        'address': element.address,
                        'quantity': inputField.value,
                        'status': statusSelect.value,
                        'isStatusChange': isActiveUpdate,
                        'isLocManagerChange': isLocManagerUpdate,
                        'locationManagers': locManagerString
                    })
                });

                if (!response || !response.ok){
                    console.log('Bad response / no response');
                    return navigate("../admin");
                }

                const data = await response.json();
                console.log(data);
                if (!isActiveUpdate && !isLocManagerUpdate && statusSelect.value === "active" && data.quantity <= 5) {
                    sendLowStockEmail(data.address, data.quantity, data.locationManagers);
                    sendLowStockText(data.address, data.quantity, data.locationManagers);
                }
            }

            locationManagers.forEach(adminName => {
                adminsAtLocation.appendChild(document.createTextNode("• " + adminName));
                adminsAtLocation.appendChild(document.createElement("br"));
            });

            if (localStorage.getItem('type') && localStorage.getItem('type') === 'superadmin'){
                const removeButton = document.createElement('button');
                removeButton.setAttribute('id', 'locationRemoveButton');
                removeButton.type = "button"
                removeButton.innerHTML = "Remove Location";
                removeButton.style.borderColor = 'red';
                removeButton.style.color = 'red';
                removeButton.style.height = "26px";
                removeButton.style.position = "relative";
                removeButton.style.lineHeight = "5px";
                removeButton.style.left = "8px";
                removeButton.onclick = removeLocation;

                async function removeLocation(){
                    removeButton.disabled = true;
                    const response = await fetch(`${API_URL}/removeLocation`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': localStorage.getItem('token')
                        },
                        body: JSON.stringify({
                            'address': element.address,
                        })
                    });
    
                    if (!response || !response.ok){
                        console.log('Bad response / no response')
                        return navigate("../admin");
                    }
    
                    const data = await response.json();
                    console.log(data);
                    tr.remove();

                    var existingAddressIdx = existingAddresses.indexOf(element.address);
                    if (existingAddressIdx !== -1){
                        existingAddresses.splice(existingAddressIdx, 1);
                    }

                    var existingLocIdx = existingLocations.indexOf(element.location);
                    if (existingLocIdx !== -1) {
                        existingLocations.splice(existingLocIdx, 1);
                    }
                    if (!existingLocations.includes(element.location)){
                        document.getElementById(element.location + 'LabelHeader').remove();
                    }
                }

                const activeOption = document.createElement("option");
                activeOption.value = "active";
                activeOption.innerHTML = "Active";
                const inactiveOption = document.createElement("option");
                inactiveOption.value = "inactive";
                inactiveOption.innerHTML = "Inactive";

                statusSelect.appendChild(activeOption);
                statusSelect.appendChild(inactiveOption);
                status.appendChild(statusSelect);
                status.appendChild(removeButton);

                statusSelect.addEventListener("change", function(event){
                    event.preventDefault();
                    updateInventory(true, false, null);
                })

                statusSelect.value = element.status;



                fillDropdown(adminSelect, "Select Admin");
                existingAdmins.forEach(element => {
                    fillDropdown(adminSelect, element[0]);
                });

                async function fillDropdown(dropdown, username){
                    if (username){
                        const dropOption = document.createElement('option');
                        dropOption.value = username;
                        dropOption.innerHTML = username;
                        dropdown.appendChild(dropOption);
                    }
                }



                function addLocManagerInputs(isRebuild){
                    if (isRebuild){
                        locationManagers.forEach(adminName => {
                            adminsAtLocation.appendChild(document.createTextNode("• " + adminName));
                            adminsAtLocation.appendChild(document.createElement("br"));
                        });
                    }

                    function addLocationManagerBtn(){
                        const addLocManagerButton = document.createElement('button');
                        addLocManagerButton.style.border = "none";
                        addLocManagerButton.style.background = "#63e600";
                        addLocManagerButton.onmouseover = function(){
                            addLocManagerButton.style.background = "#58cc00";
                        }
                        addLocManagerButton.onmouseout = function(){
                            addLocManagerButton.style.background = "#63e600";
                        }
                        addLocManagerButton.onclick = function(){
                            if (adminSelect.value !== "Select Admin" && !locationManagers.includes(adminSelect.value)){
                                adminsAtLocation.insertBefore(document.createElement("br"), adminsAtLocation.firstChild);
                                adminsAtLocation.insertBefore(document.createTextNode("• " + adminSelect.value), adminsAtLocation.firstChild);
                                locationManagers.push(adminSelect.value);
                                adminSelect.value = "Select Admin";
                                updateInventory(false, true, generateLocAdminStr())
                            }
                        }
                        const plusSign = document.createElement('img');
                        plusSign.setAttribute("class", "plusSign");
                        plusSign.src = "x.png";
                        plusSign.width = 14;
                        plusSign.height = 14;
                        plusSign.style.transform = "rotate(45deg)";
                        addLocManagerButton.appendChild(plusSign);
                        return addLocManagerButton;
                    }


                    function createLocManagerRemoveBtn(){
                        const cancelButton = document.createElement('button');
                        cancelButton.setAttribute("class", "inventoryXbtns");
                        cancelButton.style.border = "none";
                        cancelButton.style.background = "#e6001b";
                        cancelButton.onmouseover = function(){
                            cancelButton.style.background = "#cc0018";
                        }
                        cancelButton.onmouseout = function(){
                            cancelButton.style.background = "#e6001b";
                        }
                        cancelButton.onclick = function(){
                            if (adminSelect.value !== "Select Admin" && locationManagers.includes(adminSelect.value)){
                                adminsAtLocation.innerHTML = "";
                                locationManagers.splice(locationManagers.indexOf(adminSelect.value), 1);
                                adminSelect.value = "Select Admin";
                                updateInventory(false, true, generateLocAdminStr())
                                addLocManagerInputs(true); //recursion
                            }
                        }
                        const x = document.createElement('img');
                        x.setAttribute("class", "inventoryXs")
                        x.src = "minus2.png";
                        x.width = 14;
                        x.height = 14;
                        cancelButton.appendChild(x);
                        return cancelButton;
                    }

                    function generateLocAdminStr(){
                        var locMngString = "";
                        var debounce = false;
                        locationManagers.forEach(adminName => {
                            if (debounce === false){
                                debounce = true;
                                locMngString += adminName;
                            }else{
                                locMngString += "~" + adminName;
                            }
                            
                        });
                        return locMngString;
                    }
                
                    adminsAtLocation.appendChild(adminSelect);
                    adminsAtLocation.appendChild(document.createTextNode(" "));
                    adminsAtLocation.appendChild(addLocationManagerBtn());
                    adminsAtLocation.appendChild(document.createTextNode(" "));
                    adminsAtLocation.appendChild(createLocManagerRemoveBtn());
                }
                addLocManagerInputs(false)
            } else {
                status.innerHTML = element.status[0].toUpperCase() + element.status.slice(1);
            }

            const locTable = document.getElementById(element.location + 'Table');
            quantity.appendChild(inventoryInputForm);
            quantity.style.width = "325px";
            tr.appendChild(address);
            tr.appendChild(quantity);
            tr.appendChild(adminsAtLocation);
            tr.appendChild(status);
            locTable.appendChild(tr);
        }

        document.getElementById("addLocationForm").addEventListener("submit", function(event){
            event.preventDefault();
            const inputtedLocation = document.getElementById("addLocationInput");
            const inputtedAddress = document.getElementById("addAddressInput");
            //check if username doesnt already exist
            if (inputtedLocation.value !== "" && inputtedAddress.value !== "" && !existingAddresses.includes(inputtedAddress.value)){
                addLocation(inputtedLocation, inputtedAddress);
            } else {
                console.log("Address is invalid");
            }
        });


        // Duplicate function in OrderSummary
        async function sendLowStockEmail(address, quantity, locationManagerString) {
            try {
            const response = await fetch(`${API_URL}/send_mail`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    type: "admin",
                    body: "Inventory at " + address + " is running low. There are " + quantity + " bags remaining.",
                    locationManagers: locationManagerString
                })
            }).catch((error) => {
                return console.log(error);
            });
    
            if (!response || !response.ok) {
                return console.log("Bad response / no response");
            }
    
            const data = await response.json();
            console.log(data);
            } catch (error) {
            console.log(error);
            }
        };

        // Duplicate function in OrderSummary
        async function sendLowStockText(address, quantity, locationManagerString) {
            try {
            const response = await fetch(`${API_URL}/messages`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify({
                    type: "admin",
                    body: "Inventory at " + address + " is running low. There are " + quantity + " bags remaining.",
                    locationManagers: locationManagerString
                })
            }).catch((error) => {
                return console.log(error)
            })
    
            if (!response || !response.ok){
                return console.log('Bad response / no response')
            }
            const data = await response.json();
            console.log(data, "TWILIO TEST 2");
            } catch (error) {
            console.log(error);
            }
        }

    }, []);

    return(
    <div>
    <Card id="inventoryCard">
    <Card.Header> Inventory</Card.Header>
    <Card.Body>
    <Table id="inventory" bordered fluid>
    </Table>
    </Card.Body>
    <Card.Body id='addLocationCard' class="position-relative" style={{ width: '24rem' }}>
    <Table bordered fluid>
        <thead>
        <tr>
        <th>
            <form class="addLocationForm" id="addLocationForm">
                <label class="addLocationInput">Add New Location:</label>
                <input class="addLocationInput" id="addLocationInput" type="text" placeholder="Location"></input>
                <input class="addLocationInput" id="addAddressInput" type="text" placeholder="Address"></input>
                <button class="addLocationButton" id="addLocationButton" type="submit">ADD</button>
            </form>
        </th>
        </tr>
        </thead>
    </Table>
    </Card.Body>
    </Card>
    </div>
    )
};

export default Inventory;