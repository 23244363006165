import React from "react";
import {Form, Button,Card} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom';
import './admin.css';

const API_URL = process.env.REACT_APP_API;

const Admin = () => {
  const navigate = useNavigate();

  async function existingLoginCheck(){

    if (localStorage.getItem('token') === null){
      return console.log('No Token')
    }
    
    const response = await fetch(`${API_URL}/existingLoginCheck`, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          'Authorization': localStorage.getItem('token')
          //"Access-Control-Allow-Origin": "http://localhost:3000",
          //"Access-Control-Allow-Credentials": "true",
      }
      //credentials: 'include'
    }).catch((error) => {
      return console.log(error)
    })
    
    if (!response || !response.ok){
      if (localStorage.getItem("token")){
        localStorage.removeItem("token");
        const logoutButton = document.getElementById("logoutButton");
        logoutButton.setAttribute("hidden", "hidden");
      }
      return console.log('Bad response / no response')
    }
    const data = await response.json();
    //console.log(data);
    navigate("../adminOrders");
  }
  existingLoginCheck()

  const loadingMessages = ['Loading..', 'Loading...', 'Loading.'];
  let loadingIndex = 0;

  const handleSubmit = (event) => {
    event.preventDefault();
    loginCheck();

    async function loginCheck() {
      document.getElementById("loginButton").disabled = true;
      document.getElementById("loginButton").innerHTML = 'Loading.';
      var refreshInterval = setInterval(showLoading, 500);
      const response = await fetch(`${API_URL}/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "http://localhost:3000",
            //"Access-Control-Allow-Credentials": "true",
        },
        body: JSON.stringify({
            'username': document.getElementById('username').value,
            'password': document.getElementById('password').value,
        }),
        //credentials: 'include'
      }).catch((error) => {
        return console.log(error)
      })

      if (response && response.ok) {
        const data = await response.json();
        localStorage.setItem('type', data.adminType);
        localStorage.setItem('token', data.token);
        localStorage.setItem('adminUsername', data.username);
        
        // Mirrored code in Navbar.js
        const logoutButton = document.getElementById("logoutButton");
        logoutButton.removeAttribute("hidden");

        logoutButton.addEventListener("click", function(event){
          event.preventDefault();
          if (localStorage.getItem("token")){
            localStorage.removeItem("token");
          }
          logoutButton.setAttribute("hidden", "hidden");
          navigate("../")
        })

        navigate("../adminOrders");
      } else {
        document.getElementById("loginCardHeader").innerHTML = "<span style='color: red;'>Login Failed</span>";
        document.getElementById("loginButton").disabled = false;
      }

      clearInterval(refreshInterval);
      document.getElementById("loginButton").innerHTML = 'Login';
    }

    function showLoading() {
      document.getElementById('loginButton').innerText = loadingMessages[loadingIndex];
      loadingIndex = (loadingIndex + 1) % loadingMessages.length;
    }

    


  }
  
  return (
    <div style={{padding:'50px'}}>
    <Card className = "my-5 mx-auto" style={{ maxWidth: '400px', textAlign:'center',minHeight:'200px'}}>
    <Card.Header id="loginCardHeader">
      Admin Login
    </Card.Header>
    <Card.Body>
      <Form onSubmit={handleSubmit}>
      <Form.Control className = "my-1 mx-auto"
          type="text"
          id="username" name="username"
          placeholder="Username, email, or phone"
        />
      <Form.Control className = "my-2 mx-auto"
          type="password"
          id="password" name="password"
          placeholder="Password"
        />
      <Button id="loginButton" type="submit">
          Login
      </Button>
      </Form>
      </Card.Body>
      </Card>
      </div>
  );
};
  
export default Admin;