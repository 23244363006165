import {useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Form, Button, Card} from 'react-bootstrap'
import './styles.css'

function EmailForm() {
  const [values, setValues] = useState({
    email: '',
    phone: ''
  });
  const onInput = e => {
    setValues({...values, [e.target.name]: e.target.value})
    localStorage.setItem(e.target.name, e.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
  }
  return (
      <Form onSubmit={handleSubmit}>
        <p>Enter Email: </p>
        
        <Form.Control style = {{marginBottom:"23px", marginTop:"-5px"}}
          type="text"
          id="email" name="email"
          placeholder="example@gmail.com"
          onChange={onInput} 
          value={localStorage.getItem("email") || ""}
        />
        <p>Enter Phone Number (Optional): </p>
        <Form.Control style = {{marginBottom:"15px", marginTop:"-5px"}}
          type="text"
          id="phone" name="phone"
          placeholder="(XXX)-XXX-XXXX"
          onChange={onInput} 
          value={localStorage.getItem("phone") || ""}
        />
      </Form>
  );
}
export default EmailForm;