import React from "react";
import AddressSelection from "../components/addressSelection";
import {Card,ListGroup,Form,Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

const API_URL = process.env.REACT_APP_API;

const Address = () => {
    const navigate = useNavigate();

    function openMaps(){
        if (localStorage.getItem("address") && localStorage.getItem("location")){
            var searchStr = localStorage.getItem("address").split(' ').join('+') + "+" + localStorage.getItem("location");
            var url = "https://www.google.com/maps/search/" + searchStr;
            window.open(url, "_blank")
        }
    }

    function continueNextPage(){
      if (localStorage.getItem("address")){
        return(navigate("../orderConfirmation"));
      }else{
        alert("No address was selected.")
      }
    }

    return (

    <div className = "flex-container" style={{display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center",padding:'25px',flexWrap: 'wrap'}}>
      <div style={{ display:'flex',alignItems: 'center', justifyContent: "center",padding:'0px'}}>
        <Card style={{ width: '400px'}} align="center" >
          <Card.Header><p style={{fontSize:'20px',fontWeight:'bold'}}>Pickup Location</p></Card.Header>
          <Card.Body>
            <p style={{fontSize:'18px'}}>
            <ListGroup className="list-group-flush">
              <ListGroup.Item style={{padding:'25px'}}><p>Select Address</p><AddressSelection /></ListGroup.Item>
            </ListGroup>
            <br></br>
            {/* <Button variant="secondary" onClick={openMaps}>
                Open Location in Maps
            </Button> */}
            <br></br>
            <Button id="addressContinueButton" onClick={continueNextPage}>
                Continue
            </Button>
            </p>
          </Card.Body>
        </Card>
        </div>
    </div>

        
    );
};
  
export default Address;